import { Container } from "react-bootstrap";
import "./MainLayout.scss";
import {useEffect} from "react";
// import {useNavigate} from "react-router-dom";
// import {useEffect} from "react";
// import useCustomKeyboardNavigation from "../../hooks/useCustomKeyboardNavigation";

const MainLayout = ({ children, isFullScreen }) => {
  /*const navigate = useNavigate()

  useEffect(() => {
    // 로컬 스토리지 정보 체크해서 유저정보 확인 후 접근 허용
    const user = JSON.parse(localStorage.getItem("smartsilver-user"));
    // 유저 정보 없을 시 404 페이지로 이동
    if (!user) {
      navigate("/404");
    } else {
      // 유저 정보 있을 시 메인 페이지로 이동
      navigate("/");
    }
  }, []);*/

  // useCustomKeyboardNavigation(); // 글로벌 키보드 네비게이션 Hook 호출

  return (
    // <Container fluid className={"layout-main p-5"}>
    <Container
      fluid
      className={"layout-main"}
      style={
        isFullScreen
          ? {
              paddingRight: "0",
              paddingLeft: "0",
            }
          : {
              padding: "3vh 3vw",
              gap: "2vw",
            }
      }
    >
      {children}
    </Container>
  );
};

export default MainLayout;
