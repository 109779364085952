// web-client/src/App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import NotFoundPage from "./pages/NotFoundPage";
import Broadcast from "./components/Broadcast/Broadcast";
// import BroadcastTest from "./components/Broadcast/BroadcastTest";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/server" element={<Broadcast />} />
        {/*<Route path="/server-test" element={<BroadcastTest/>}/>*/}
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
