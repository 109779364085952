import React, { useEffect, useState } from "react";
import {
  getCenterPolling,
  getJWT,
  getLiveStreaming,
} from "../../../api/apiClient";
import Media from "../additional/media";
import Conf_screen from "./conf_screen";
import NoStreamingImg from "../../../assets/broadcast/no_streaming.jpg";
import InspectionImg from "../../../assets/common/inspection.jpg";
import styled from "styled-components";
import ScaleExpandingSVG from "../../../assets/broadcast/scale-expanding.svg";
import ScaleReductionSVG from "../../../assets/broadcast/scale-reduction.svg";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import LoadingSpinner from "../../Layout/LoadingSpinner";
import { Button } from "react-bootstrap";
import FirstSVG from "../../../assets/layout-icon/first.svg";
import FirstCursorSVG from "../../../assets/layout-icon/first-cursor.svg";
import FirstActiveSVG from "../../../assets/layout-icon/first-active.svg";
import SecondSVG from "../../../assets/layout-icon/second.svg";
import SecondCursorSVG from "../../../assets/layout-icon/second-cursor.svg";
import SecondActiveSVG from "../../../assets/layout-icon/second-active.svg";
import ThirdSVG from "../../../assets/layout-icon/third.svg";
import ThirdCursorSVG from "../../../assets/layout-icon/third-cursor.svg";
import ThirdActiveSVG from "../../../assets/layout-icon/third-active.svg";

const FullScreenButton = styled.div`
  position: absolute;
  bottom: 1%;
  right: 1%;
  z-index: 99;
  border: ${(props) => (props.focused ? "3px solid white" : "none")};
`;

const LayoutButton = styled.div`
  position: absolute;
  bottom: 1%;
  left: 1%;
  /* transform: translateX(-50%); */
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #1f1f1f;
  padding: 1rem;
  border-radius: 1rem;
`;

const LayoutItem = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  border: ${(props) => (props.focused ? "3px solid #3FB5EC" : "none")};
  border-radius: 8px;
`;

const Streaming = ({
  centerInfo,
  isFullScreen,
  setIsFullScreen,
  focusKey: focusKeyParam,
}) => {
  /*const {ref, focused, focusKey} = useFocusable({
    focusKey: focusKeyParam,
    autoFocus: true,
    onEnterPress: () => {
      setIsFullScreen && setIsFullScreen(!isFullScreen);
    },
  });*/

  // const [selectedLayout, setSelectedLayout] = useState(null);
  // layout button focused
  /*const { ref: firstLayoutRef, focused: firstLayoutFocused } = useFocusable({
    focusKey: "firstLayout",
    onEnterPress: () => {
      console.log("firstLayout enter");
      setSelectedLayout("first");
    },
  });
  const { ref: secondLayoutRef, focused: secondLayoutFocused } = useFocusable({
    focusKey: "secondLayout",
    onEnterPress: () => {
      console.log("secondLayout enter");
      setSelectedLayout("second");
    },
  });
  const { ref: thirdLayoutRef, focused: thirdLayoutFocused } = useFocusable({
    focusKey: "thirdLayout",
    onEnterPress: () => {
      console.log("thirdLayout enter");
      setSelectedLayout("third");
    },
  });*/

  const [isLoading, setIsLoading] = useState(true); // 초기 로딩 상태 true
  const [isFirstLoading, setIsFirstLoading] = useState(true); // 첫 로딩 체크

  useEffect(() => {
    centerInfo && console.log("centerInfo: ", centerInfo);
  }, [centerInfo]);

  const [token, setToken] = useState("");

  useEffect(() => {
    if (Object.keys(centerInfo).length !== 0) {
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [centerInfo]);

  useEffect(() => {
    console.log("token: ", token);
  }, [token]);

  const [current, setCurrent] = useState({});

  // api call
  // const getCurrent = () => {
  //   if (isFirstLoading) setIsLoading(true);

  //   // get current streaming info
  //   getLiveStreaming()
  //     .then((res) => {
  //       console.log("Current streaming info: ", res);
  //       setCurrent(res);
  //     })
  //     .catch((error) => {
  //       console.error("Current streaming info error: ", error);
  //       setCurrent("error");
  //     })
  //     .finally(() => {
  //       if (isFirstLoading) {
  //         setIsLoading(false);
  //         setIsFirstLoading(false);
  //       }
  //     });
  // };

  const getCurrent = () => {
    if (isFirstLoading) setIsLoading(true);

    // get current streaming info
    getCenterPolling(centerInfo?.center_id)
      .then((res) => {
        console.log("Current streaming info: ", res);
        setCurrent(res);
      })
      .catch((error) => {
        console.error("Current streaming info error: ", error);
        setCurrent("error");
      })
      .finally(() => {
        if (isFirstLoading) {
          setIsLoading(false);
          setIsFirstLoading(false);
        }
      });
  };

  useEffect(() => {
    getCurrent();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getCurrent();
    }, 10000);
  }, []);

  useEffect(() => {
    console.log("current: ", current);
  }, [current]);

  return (
    <>
      {/*<FocusContext.Provider value={focusKey}>*/}
      {isLoading && isFirstLoading && <LoadingSpinner type="streaming" />}

      {/* 에러 처리 */}
      {current === "error" && (
        <>
          <img src={InspectionImg} alt="Inspection" style={{ width: "100%" }} />
        </>
      )}

      {/* 방송이 있을 경우 (스트리밍) */}
      {current &&
        current.data &&
        current.data.broadcast.content_type === "live" &&
        token && (
          <>
            <Conf_screen
              token={token}
              setToken={setToken}
              centerInfo={centerInfo}
              // selectedLayout={selectedLayout}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              focusKey={focusKeyParam}
            />
            {/* 레이아웃 버튼 */}
            {/*<LayoutButton>
              <LayoutItem ref={firstLayoutRef} focused={firstLayoutFocused}>
                <img
                  src={selectedLayout === "first" ? FirstActiveSVG : FirstSVG}
                  alt="first-layout"
                />
              </LayoutItem>
              <LayoutItem ref={secondLayoutRef} focused={secondLayoutFocused}>
                <img
                  src={
                    selectedLayout === "second" ? SecondActiveSVG : SecondSVG
                  }
                  alt="second-layout"
                />
              </LayoutItem>
              <LayoutItem ref={thirdLayoutRef} focused={thirdLayoutFocused}>
                <img
                  src={selectedLayout === "third" ? ThirdActiveSVG : ThirdSVG}
                  alt="third-layout"
                />
              </LayoutItem>
            </LayoutButton>*/}
          </>
        )}

      {/* 방송이 있을 경우 (VOD) */}
      {current &&
        current.data &&
        current.data.broadcast.content_type === "vod" && (
          <>
            <Media
              videoUrl={current.data.broadcast.vod.video}
              startTime={current.data.broadcast.start_time}
              endTime={current.data.broadcast.end_time}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              focusKey={focusKeyParam}
            />
          </>
        )}

      {/* 방송이 없을 경우 */}
      {((current && current.data && current.data.message) ||
        current.length === 0) && (
        <>
          <img
            src={NoStreamingImg}
            alt="No streaming"
            style={{ width: "100%" }}
          />
        </>
      )}

      {/* 전체화면 버튼 처리 */}
      {/*{!isFullScreen && (*/}
      {/*<FullScreenButton
        ref={ref}
        focused={focused}
        onClick={() => {
          // console.log("FullScreenButton Clicked");
          setIsFullScreen(!isFullScreen);
        }}
      >
        <img
          tabIndex={0}
          src={isFullScreen ? ScaleReductionSVG : ScaleExpandingSVG}
          // onClick={() => setIsFullscreen(!isFullScreen)}

          alt={"scale"}
          className={""}
        />
      </FullScreenButton>*/}
      {/*)}*/}
      {/*</FocusContext.Provider>*/}
    </>
  );
};

export default Streaming;
