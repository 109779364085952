import React, { useRef, useEffect, useState } from "react";
// import "./BusInfoCard.scss";
import { Col, Row } from "react-bootstrap";
import BusR from "../../../assets/bus/bus-R.svg";
import BusB from "../../../assets/bus/bus-B.svg";
import BusGY from "../../../assets/bus/bus-GY.svg";
import styled from "styled-components";

const BusInfoCard = ({ busInfo, primary, gradient }) => {
  const itemList =
    busInfo?.bus_info?.ServiceResult?.msgBody?.itemList?.sort(
      (a, b) => a.ARRIVALESTIMATETIME - b.ARRIVALESTIMATETIME
    ) || [];

  // 2분전 도착 버스
  const soonArrivalBus = itemList.filter(
    (bus) => bus.ARRIVALESTIMATETIME <= 120
  );

  // 버스 곧도착 애니메이션
  const arriveBusRef = useRef(null); // 부모 컨테이너 참조
  const scrollContainerRef = useRef(null); // 스크롤 컨테이너 참조
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      if (scrollContainerRef.current && arriveBusRef.current) {
        const containerWidth = arriveBusRef.current.offsetWidth - 50;
        const contentWidth = Array.from(
          scrollContainerRef.current.querySelectorAll(".scroll-content")
        ).reduce((acc, el) => acc + el.offsetWidth, 0); // 항목들의 총 너비 합산

        // console.log("Container Width:", containerWidth);
        // console.log("Content Width:", contentWidth);

        setIsScrollable(contentWidth > containerWidth);
      }
    };

    // DOM 렌더링 이후 강제로 실행 보장
    setTimeout(checkScrollable, 0);

    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [itemList]);

  return (
    <BusInfo
      className={`bus-info text-center d-flex flex-column gap-3`}
      $primary={primary}
      $gradient={gradient}
    >
      <Col className={`bus-title`}>
        <h4 className="fs-1 fw-bold">{busInfo?.name}</h4>
      </Col>
      <Col className="bus-arrival d-flex justify-content-between">
        <Col xs={4} className="arrive-bg">
          <h4 className="fs-1 fw-bold">곧 도착</h4>
        </Col>
        <Col
          xs={8}
          className="d-flex justify-content-center align-items-center gap-3 arrive-bus"
          ref={arriveBusRef}
        >
          <div
            className={`scroll-container d-flex align-items-center gap-3 ${
              isScrollable ? "scroll-animate" : ""
            }`}
            ref={scrollContainerRef}
          >
            {soonArrivalBus ? (
              soonArrivalBus?.map((soonBus) => (
                <div key={soonBus.BUSID} className="scroll-content">
                  <img
                    className="d-block"
                    src={primary === "#3A79C0" ? BusB : BusR}
                    alt="bus-img01"
                  />
                  <span className="fs-1 fw-bold">{soonBus?.ROUTENO}</span>
                </div>
              ))
            ) : (
              <Col></Col>
            )}
            {isScrollable &&
              soonArrivalBus.map((soonBus) => (
                <div key={soonBus.BUSID} className="scroll-content">
                  <img
                    className="d-block"
                    src={primary === "#3A79C0" ? BusB : BusR}
                    alt="bus-img01"
                  />
                  <span className="fs-1 fw-bold">{soonBus?.ROUTENO}</span>
                </div>
              ))}
          </div>
        </Col>
      </Col>
      <Col className="bus-list w-100">
        <Col className="bus-list-title fs-3">
          <Row className="mx-3 px-4 py-2">
            <Col xs={4} className="border-end">
              버스 번호
            </Col>
            <Col xs={4} className="border-end">
              남은 시간
            </Col>
            <Col xs={4} className="">
              남은 정거장
            </Col>
          </Row>
        </Col>
        <Col className="bus-list-contents w-100">
          {busInfo?.bus_info?.ServiceResult?.msgBody && itemList ? (
            itemList?.map((bus, idx) => (
              <Row
                key={idx}
                className="px-3 py-1 bus-list-content align-items-center mx-3 border-bottom"
              >
                <Col
                  xs={4}
                  className="px-0 bus-number border-end d-flex justify-content-center align-items-center gap-2"
                >
                  <img
                    className=""
                    src={BusGY}
                    alt="bus-img02"
                    style={{ width: "35px", height: "35px" }}
                  />
                  <span
                    className="fs-1 fw-bold text-start"
                    style={{ width: "12rem" }}
                  >
                    {/*{bus.BUSID}*/}
                    {bus.ROUTENO}
                  </span>
                </Col>
                <Col xs={4} className="border-end">
                  <span className="fs-2 fw-bold">
                    <b className="" style={{ fontSize: "3.5rem" }}>
                      {Math.ceil(bus.ARRIVALESTIMATETIME / 60)}
                    </b>
                    분 후
                  </span>
                </Col>
                <Col xs={4}>
                  <h4 className="fs-1 fw-bold">{bus.REST_STOP_COUNT}정류장</h4>
                </Col>
              </Row>
            ))
          ) : (
            <Col className="text-black fs-5 mt-4">도착 정보가 없습니다.</Col>
          )}
        </Col>
      </Col>
    </BusInfo>
  );
};

export default BusInfoCard;

// ========= styled-components =========
const BusInfo = styled.div`
  width: 50%;
  height: 100%;

  .bus-title {
    max-height: 5rem;
    border-radius: var(--radius-20) var(--radius-20) 0 0;
    background-color: var(--gray-opacity-2);

    h4 {
      line-height: 5rem;
    }
  }

  .bus-arrival {
    max-height: 5rem;
    box-shadow: inset 0 0 0 2px ${(props) => props.$primary}; /* 내부 테두리 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 크기 계산 */
    border-radius: var(--radius-20);
    overflow: hidden;
    background-color: var(--white);

    .arrive-bg {
      background: linear-gradient(
        to bottom,
        ${(props) => props.$gradient},
        ${(props) => props.$primary}
      );

      h4 {
        line-height: 5rem;
      }
    }

    .arrive-bus {
      max-width: 35rem;
      overflow: hidden;
      position: relative;

      .scroll-container {
        max-width: 30rem;
        white-space: nowrap;
        gap: var(--gap-size); /* gap 크기 정의 */
        --gap-size: 1rem; /* gap 기본값 */

        &.scroll-animate {
          animation: scroll 10s linear infinite; /* 애니메이션 */
        }
      }

      .scroll-content {
        display: flex;
        align-items: center;
        gap: var(--gap-size); /* gap 크기 유지 */
      }

      img {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
      }

      span {
        color: var(--gray-2);
        white-space: nowrap;
        flex-shrink: 0;
        font-size: 3rem;
        font-weight: bold;
      }
    }
  }

  /* Keyframes */
  @keyframes scroll {
    from {
      transform: translateX(0); /* 시작 위치 */
    }
    to {
      transform: translateX(
        calc(-100% - var(--gap-size))
      ); /* 끝 위치 (gap 포함) */
    }
  }

  .bus-list {
    border: 2px solid ${(props) => props.$primary};
    border-radius: var(--radius-20);
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: var(--white);

    .bus-list-title {
      background-color: ${(props) => props.$primary};
      max-height: 4rem;
      padding-right: 1rem;
    }

    .bus-list-contents {
      max-height: calc(100% - 4rem); /* 제목 높이를 제외한 영역 */
      /* overflow-y: auto; 세로 스크롤 추가 */
      overflow-y: hidden;
      padding-right: 0.5rem; /* 스크롤바와 내용 간격 */

      .bus-list-content {
        img {
          width: 2.5rem;
          height: 2.5rem;
        }

        span,
        h4 {
          color: var(--black-2);

          b {
            color: ${(props) => props.$primary};
          }
        }
      }
    }
  }
`;
