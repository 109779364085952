import React, { useEffect } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { getJWT } from "../../../api/apiClient";
import { jwtDecode } from "jwt-decode";

const handleJaaSIFrameRef = (iframeRef) => {
  iframeRef.style.border = "0px solid #3d3d3d";
  iframeRef.style.background = "#3d3d3d";
  iframeRef.style.height = "1080px";
};

// temporal id
var YOUR_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd";

function Call_screen({ token, roomname, setToken, centerInfo }) {
  useEffect(() => {
    console.log("token: ", token);

    if (token) {
      const decodedToken = jwtDecode(token);
      const expTime = decodedToken.exp * 1000; // 밀리초로 변환

      // 만료 시간 확인 후 갱신 요청 설정
      if (Date.now() >= expTime) {
        requestNewToken(); // 이미 만료된 경우
      } else {
        const timeUntilExpiry = expTime - Date.now();
        const refreshBuffer = 2 * 60 * 1000; // 만료 2분 전 갱신

        setTimeout(requestNewToken, timeUntilExpiry - refreshBuffer); // 갱신 타이머 설정
      }
    }
  }, [token]);

  // 새 토큰 요청 함수
  const requestNewToken = async () => {
    if (Object.keys(centerInfo).length !== 0) {
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <JaaSMeeting
      appId={YOUR_APP_ID}
      roomName={roomname}
      jwt={token}
      configOverwrite={{
        startWithAudioMuted: false,

        prejoinPageEnabled: false,
      }}
      getIFrameRef={handleJaaSIFrameRef}
    />
  );
}

Call_screen.propTypes = {};

export default Call_screen;
