import React from "react";
import BusInfoCard from "./BusInfoCard";
import { Col, Row } from "react-bootstrap";
import { getBusArrival, getBusStatus } from "../../../api/apiClient";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../../Layout/LoadingSpinner";

const BusArrived = ({ centerInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [busInfo, setBusInfo] = useState(null);
  const [isBusOpenLoading, setIsBusOpenLoading] = useState(true);
  const [isBusInfoLoading, setIsBusInfoLoading] = useState(true);

  const getBus = () => {
    setIsBusOpenLoading(true);

    getBusStatus()
      .then((res) => {
        setIsOpen(res.data.bus);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsBusOpenLoading(false));
  };

  useEffect(() => {
    getBus();
  }, []);

  const getBusArrived = (isFirstLoad = false) => {
    if (isFirstLoad) setIsBusInfoLoading(true);

    getBusArrival(centerInfo.center_id)
      .then((res) => {
        console.log("bus", res.data);
        setBusInfo(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        if (isFirstLoad) setIsBusInfoLoading(false);
      });
  };

  useEffect(() => {
    getBusArrived(true);

    const interval = setInterval(() => {
      getBusArrived(false);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isBusOpenLoading ? (
        <LoadingSpinner />
      ) : isOpen ? (
        isBusInfoLoading ? (
          <LoadingSpinner />
        ) : (
          <Row className="row-gap-2 p-3 h-100">
            <BusInfoCard
              busInfo={busInfo?.station_1_info}
              primary={"#CD3E3E"}
              gradient={"#f17474"}
            />
            <BusInfoCard
              busInfo={busInfo?.station_2_info}
              primary={"#3A79C0"}
              gradient={"#6abdf1"}
            />
          </Row>
        )
      ) : (
        <Row className="text-white p-5 row-gap-3">
          <Col xs={12}>
            <h3>버스 정보를 가져올 수 없습니다.</h3>
          </Col>
          <Col xs={12}>
            <h3>관리자에게 문의하세요.</h3>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BusArrived;
