import React from "react";
import Slide from "./FooterContent/Slide";
import Null from "./FooterContent/Null";
import styled from "styled-components";

const FooterWrapper = styled.div`
    padding-bottom: 0;
    /* background-color: pink; */
    border-radius: 16px;
    //margin: 0.5rem 0;
`;

const Footer = ({selectedComponent}) => {
  return (
    <FooterWrapper className="footer">
      {selectedComponent === "실시간 방송" ? (
        <Slide/>
      ) : null}
      {(selectedComponent === "TV 보기" || selectedComponent === null) && <Null/>}
    </FooterWrapper>
  );
};

export default Footer;
