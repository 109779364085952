import React, { useEffect, useState } from "react";
import MainContent from "./contents/MainContent";
import { getWeatherStatus } from "../../../api/apiClient";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Weather({ isFullscreen }) {
  const [isOpen, setIsOpen] = useState(false);

  const getWeather = () => {
    getWeatherStatus()
      .then((res) => {
        console.log("getWeather", res.data);
        setIsOpen(res.data.weather);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <>
      {isOpen ? (
        <MainContent isFullscreen={isFullscreen} />
      ) : (
        <>
          <Row className={"text-white p-5 row-gap-3"}>
            <Col xs={12}>
              <h5>날씨 정보를 가져올 수 없습니다.</h5>
            </Col>
            <Col xs={12}>
              <h5>관리자에게 문의하세요.</h5>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Weather;
