import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
// import Before from "../../../assets/broadcast/before.svg";
// import BlurPng from "../../../assets/common/blurImg.png";
import {createVideoCall, getJWT} from "../../../api/apiClient";
import {
  // FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import CenterRow from "./CenterRow";
import Popup from "../../Layout/popup/Popup";
import KeyboardDebugger from "../../../hooks/KeyboardDebugger";

const SeniorCenter = ({
                        activeBorough,
                        boroughList,
                        activeMenu,
                        setActiveMenu,
                        token,
                        centerInfo,
                        setToken,
                        setNewRoomname,
                      }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [previousFocus, setPreviousFocus] = useState(null); // 이전 포커스 저장

  useLayoutEffect(() => {
    setTimeout(() => {
      setFocus("backButton"); // 페이지 로드 시 "목록보기" 버튼에 포커스
    }, 0);
  }, []);

  const openPopup = () => {
    setPreviousFocus(getCurrentFocusKey()); // 팝업이 열리기 전에 현재 포커스 저장
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setTimeout(() => {
      if (previousFocus) {
        setFocus(previousFocus); // 팝업이 닫힐 때 저장된 포커스로 복귀
      }
    }, 0);
  };

  const {ref, focused} = useFocusable({
    focusKey: "backButton",
    onEnterPress: () => backToBoroughSelection(),
  });

  const {ref: callRef, focused: callFocused} = useFocusable({
    focusKey: "callButton",
    onEnterPress: () => {
      openPopup();
    },
  });

  // const { ref: centerRef, focused: centerFocused } = useFocusable({
  //   onEnterPress: () => console.log("enter"),
  // });

  const [selectSeniorCenterList, setSelectSeniorCenterList] = useState([]);

  useEffect(() => {
    setSelectSeniorCenterList([]);
  }, [activeBorough]);

  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const lastFocusedElementRef = useRef(null);

  const onHandleFocus = (e) => {
    const focusedElement = e.target;
    // const dataId = focusedElement.getAttribute('data-id');
    lastFocusedElementRef.current = focusedElement;
  };

  // useLayoutEffect(() => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollTop = scrollPositionRef.current;
  //   }
  // });

  useLayoutEffect(() => {
    if (lastFocusedElementRef.current) {
      setTimeout(() => {
        lastFocusedElementRef.current.focus();
      }, 0);
    }
  }, [activeMenu]);

  let centers = [];
  if (activeBorough === "all") {
    centers = boroughList
      .flatMap((borough) =>
        borough.centers.map((center) => ({
          ...center,
          boroughName: borough.name,
        }))
      )
      .filter((center) => center.boroughName !== "all");
  } else {
    const borough = boroughList.find((b) => b.name === activeBorough);
    centers = borough
      ? borough.centers.map((center) => ({
        ...center,
        boroughName: borough.name,
      }))
      : [];
  }

  const selectSeniorCenter = (center, e) => {
    scrollPositionRef.current = scrollContainerRef.current.scrollTop;
    setSelectSeniorCenterList([
      ...selectSeniorCenterList.filter((c) => c.id !== center.id),
      center,
    ]);
    lastFocusedElementRef.current = e.currentTarget;
  };

  const deleteSeniorCenter = (center, e) => {
    scrollPositionRef.current = scrollContainerRef.current.scrollTop;
    setSelectSeniorCenterList(
      selectSeniorCenterList.filter((c) => c.id !== center.id)
    );
    lastFocusedElementRef.current = e.currentTarget;
  };

  const selectedNames = selectSeniorCenterList
    .map((item) => item.name)
    .join(", ");

  const [selectedCenterText, setSelectedCenterText] = useState("수신 경로당명");

  const connectCall = () => {
    if (selectSeniorCenterList.length === 0) {
      setSelectedCenterText("수신 경로당을 선택해주세요.");
      return;
    }
    // 방 생성 기능
    createJitsiRoom(selectSeniorCenterList);
  };

  const [newToken, setNewToken] = useState("");

  const createJitsiRoom = (selectSeniorCenterList) => {
    // 방 생성자 정보
    const creator = centerInfo.center_id;
    // unix 현재 시간 (방 생성 시간)
    const unixTime = Math.floor(new Date().getTime() / 1000);
    // 선택된 경로당 이름들을 , 로 구분하여 문자열로 변환
    const ListToText =
      creator +
      "," +
      selectSeniorCenterList.map((item) => item.name).join(", ");
    // 방 생성 이름
    const roomName = `ICSC-인천-영상통화-${ListToText}-${unixTime}`;

    const data = {
      title: roomName,
      creator: creator,
      participants: selectSeniorCenterList.map((item) => item.id),
    };

    // 방 생성
    createVideoCall(data)
      .then((res) => {
        console.log("createRoom res : ", res.data);
        setNewRoomname(res.data.title);

        // 새로운 토큰 발급
        getJWT(centerInfo?.name)
          .then((res) => {
            setNewToken(res.data.token.slice(2, -1));
            setToken(res.data.token.slice(2, -1));
            // setIsFullscreen(true);
            setActiveMenu("communityCenterVideoCall");
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const backToBoroughSelection = () => {
    setActiveMenu("boroughSelection");
  };

  // 포커스 될 때 스크롤 이벤트
  const lastFocusedY = React.useRef(0);

  const onRowFocus = React.useCallback(
    ({y}) => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        // 방향키 이동 시 포커스가 변경된 후 `y` 값을 가져오도록 딜레이 추가
        requestAnimationFrame(() => {
          let scrollTo = y - containerHeight / 2;
          scrollTo = Math.max(
            0,
            Math.min(scrollTo, scrollHeight - containerHeight)
          );

          if (lastFocusedY.current !== scrollTo) {
            container.scrollTo({
              top: scrollTo,
              behavior: "instant",
            });
            lastFocusedY.current = scrollTo;
          }
        });
      }
    },
    [scrollContainerRef]
  );


  /* 리모컨 목록보기 입력 기능 */
  const keyboard = KeyboardDebugger();

  useEffect(() => {
    if (keyboard === "BrowserBack") {
      backToBoroughSelection();
    }
  }, [keyboard]);

  return (
    <Row className="" style={{overflow: "hidden"}}>
      <Col
        ref={ref}
        className="mb-3"
        style={{
          paddingLeft: "3rem",
        }}
      >
        <Button
          className={`back-btn ${focused ? "focused" : ""}`}
          focused={focused}
          // variant={focused ? "primary" : "secondary"}
        >
          {"\u003c 목록보기"}
        </Button>
      </Col>
      <Row
        className="fs-2 py-4 fw-bold custom-border-secondary"
        style={{
          backgroundColor: "#2D2D2D",
          boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
        }}
      >
        <Col
          xs={9}
          className="border-end custom-border-secondary my-auto align-middle"
          style={{
            padding: "0 3.5rem",
          }}
        >
          <span>
            {selectSeniorCenterList.length === 0
              ? selectedCenterText
              : selectedNames}
          </span>
        </Col>
        <Col
          xs={3}
          ref={callRef}
          className="text-center my-auto align-middle"
          style={{
            paddingRight: "5rem",
          }}
        >
          <Button
            // tabIndex={0}
            variant={"secondary"}
            // ref={callRef}
            focused={callFocused}
            className={"ms-4 px-5"}
            style={{
              border: callFocused ? "4px solid #3FB5EC" : "",
              backgroundColor: "#fff",
              color:
                selectSeniorCenterList.length === 0 ? "rgba(0,0,0,0.5)" : "",
            }}
            disabled={selectSeniorCenterList.length === 0}
            // onClick={(e) => {
            //   e.preventDefault();
            //   connectCall();
            // }}
            // onKeyDown={(e) => {
            //   e.preventDefault();
            //   if (e.key === "Enter") {
            //     connectCall();
            //   }
            // }}
            // onFocus={onHandleFocus}
          >
            전화걸기
          </Button>
        </Col>
      </Row>

      <div
        ref={scrollContainerRef}
        style={{
          maxHeight: "calc(100vh - 17rem)",
          overflowY: "auto",
          zIndex: 1,
        }}
      >
        {centers.map((center, idx) => {
          const isSelected = selectSeniorCenterList.some(
            (c) => c.id === center.id
          );
          const boroughNameKor = center.boroughName;
          return (
            <CenterRow
              key={idx}
              center={center}
              isSelected={isSelected}
              boroughNameKor={boroughNameKor}
              selectSeniorCenter={selectSeniorCenter}
              deleteSeniorCenter={deleteSeniorCenter}
              onFocus={onRowFocus}
            />
          );
        })}

        {centers.length === 0 && (
          <div className={"fs-3 text-center py-4"}>
            해당 구에 등록된 경로당이 없습니다.
          </div>
        )}
      </div>

      {/* <div className={"scale"}>
        <img
          src={Before}
          alt="before"
          className={`position-absolute return-icon z-3 end-0 bottom-0 cursor-hover ${"full"}`}
          tabIndex={0}
          onClick={backToBoroughSelection}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              backToBoroughSelection();
            }
          }}
          onFocus={onHandleFocus}
        />
      </div> */}
      {isPopupOpen && (
        <Popup
          show
          popupKey={"connect"}
          type={"connect"}
          text={`${selectedNames}에게 영상통화를 하시겠습니까?`}
          onCancel={closePopup} // 닫기 버튼 클릭 시 포커스 복귀
          onConfirm={() => {
            closePopup();
            connectCall(); // 연결 버튼 클릭 시 영상통화 실행
            setTimeout(() => {
              setFocus("VIDEO_CALL");
            }, 0);
          }}
        />
      )}
    </Row>
  );
};

export default SeniorCenter;
