import React from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import styled from "styled-components";

const ButtonBox = styled.div`
  border-width: ${({ focused }) => (focused ? "3px" : "3px")};
  background-color: ${({ focused }) => (focused ? "#3FB5EC" : "#FFFFFF")};
  border-color: ${({ focused }) => (focused ? "#3FB5EC" : "#9B9B9B")};
  color: ${({ focused }) => (focused ? "#000000" : "#000000")};
  font-weight: ${({ focused }) => (focused ? "700" : "500")};
  border-style: solid;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1.75rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

const PopupButton = ({ name, label, onSelect }) => {
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      onSelect(label);
    },
  });

  return (
    <>
      <ButtonBox ref={ref} focused={focused} data-testid={"popup-button"}>
        {label}
      </ButtonBox>
    </>
  );
};

export default PopupButton;
