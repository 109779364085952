import React, { useEffect, useState } from "react";
import CardDiv from "./CardDiv";
import { getDate, getTime } from "../../../../util/util";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CurrentWeather({ currentWeather, isFullscreen }) {
  const {
    weather,
    dt: dataUnix,
    main: { temp },
  } = currentWeather;

  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState(undefined);

  useEffect(() => {
    // Set time and date immediately on first render
    setTime(getTime());
    setDate(getDate());

    const intervalId = setInterval(() => {
      setTime(getTime());
      setDate(getDate());
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  if (!currentWeather) {
    return <div>Loading...</div>;
  }

  const [{ icon }] = weather;
  const iconUrl = require(`../../../../assets/images/${icon}.png`);

  return (
    <CardDiv size="large" className={""} isFullscreen={isFullscreen}>
      <Row className={"row-gap-2 justify-content-center"}>
        <Col xs={12} className={"text-center"}>
          <Row className={"styles.list row-gap-3 mt-2"}>
            <Col xs={12} className={"styles.item align-middle my-auto"}>
              <span
                className={"styles.text ms-2 align-middle my-auto fs-6 me-2"}
              >
                {date}
              </span>
              <span className="fs-2">{time}</span>
            </Col>
          </Row>
        </Col>

        <Col xs={12} className={""}>
          <Row>
            <Col xs={7} className={"align-middle my-auto text-center"}>
              <span className={"align-middle my-auto fs-1"}>
                {`${Math.round(temp)}°`}
                <sup style={{}}>c</sup>
              </span>
            </Col>
            <Col xs={5} className={" align-middle my-auto text-center"}>
              <img
                src={iconUrl}
                alt="clouds"
                className={"styles.img align-middle my-auto"}
                style={{ width: "70%", height: "70%" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardDiv>
  );
}
