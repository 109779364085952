import React from "react";
import Col from "react-bootstrap/Col";
import Text from "../../../assets/main/iptv-text.png";

const Null = () => {
  return (
    <Col
      className="h-100"
      style={{
        borderRadius: "16px",
        overflow: "hidden",
      }}
    >
      <img
        src={Text}
        alt=""
        className="w-100 h-100 mt-3"
        style={{
          borderRadius: "16px",
          objectFit: "cover",
          objectPosition: "center center",
        }}
      />
    </Col>
  );
};

export default Null;
