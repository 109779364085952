import React, {useEffect, useState} from "react";
import {getJWT} from "../../../api/apiClient";
import {jwtDecode} from "jwt-decode";
import Conf_custom from "./conf_custom";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import LoadingSpinner from "../../Layout/LoadingSpinner";
import {Button} from "react-bootstrap";
import FirstSVG from "../../../assets/layout-icon/first.svg";
import FirstCursorSVG from "../../../assets/layout-icon/first-cursor.svg";
import FirstActiveSVG from "../../../assets/layout-icon/first-active.svg";
import SecondSVG from "../../../assets/layout-icon/second.svg";
import SecondCursorSVG from "../../../assets/layout-icon/second-cursor.svg";
import SecondActiveSVG from "../../../assets/layout-icon/second-active.svg";
import ThirdSVG from "../../../assets/layout-icon/third.svg";
import ThirdCursorSVG from "../../../assets/layout-icon/third-cursor.svg";
import ThirdActiveSVG from "../../../assets/layout-icon/third-active.svg";
import styled, {keyframes} from "styled-components";
import ScaleReductionSVG from "../../../assets/broadcast/scale-reduction.svg";
import ScaleExpandingSVG from "../../../assets/broadcast/scale-expanding.svg";
import KeyboardDebugger from "../../../hooks/KeyboardDebugger";

const FullScreenButton = styled.div`
    position: absolute;
    bottom: 1%;
    right: 1%;
    z-index: 99;
    border: ${(props) => (props.focused ? "3px solid white" : "none")};
`;

const slideUp = keyframes`
    from {
        transform: translateY(120%);
    }
    to {
        transform: translateY(0);
    }
`;

const slideDown = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(120%);
    }
`;

const LayoutButton = styled.div`
    position: absolute;
    bottom: 1%;
    left: 1%;
    /* transform: translateX(-50%); */
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: #1f1f1f;
    padding: 1rem;
    border-radius: 1rem;
    animation: ${(props) => (props.show ? slideUp : slideDown)} 0.5s forwards;
`;

const LayoutItem = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    border: ${(props) => (props.focused ? "3px solid #3FB5EC" : "none")};
    border-radius: 8px;
`;

function Conf_screen({
                       token,
                       setToken,
                       centerInfo,
                       isFullScreen,
                       setIsFullScreen,
                       focusKey: focusKeyParam,
                     }) {
  const {ref, focused, focusKey} = useFocusable({
    // focusKey: focusKeyParam,
    focusKey: "fullScreen",
    autoFocus: true,
    onEnterPress: () => {
      setIsFullScreen && setIsFullScreen(!isFullScreen);
    },
  });

  const [selectedLayout, setSelectedLayout] = useState("third");
  const [showLayoutButton, setShowLayoutButton] = useState(false);

  const {ref: firstLayoutRef, focused: firstLayoutFocused} = useFocusable({
    focusKey: "firstLayout",
    onEnterPress: () => {
      console.log("firstLayout enter");
      setSelectedLayout("first");
    },
  });
  const {ref: secondLayoutRef, focused: secondLayoutFocused} = useFocusable({
    focusKey: "secondLayout",
    onEnterPress: () => {
      console.log("secondLayout enter");
      setSelectedLayout("second");
    },
  });
  const {ref: thirdLayoutRef, focused: thirdLayoutFocused} = useFocusable({
    focusKey: "thirdLayout",
    onEnterPress: () => {
      console.log("thirdLayout enter");
      setSelectedLayout("third");
    },
  });

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const expTime = decodedToken.exp * 1000; // 밀리초로 변환

      // 만료 시간 확인 후 갱신 요청 설정
      if (Date.now() >= expTime) {
        requestNewToken(); // 이미 만료된 경우
      } else {
        const timeUntilExpiry = expTime - Date.now();
        const refreshBuffer = 2 * 60 * 1000; // 만료 2분 전 갱신

        setTimeout(requestNewToken, timeUntilExpiry - refreshBuffer); // 갱신 타이머 설정
      }
    }
  }, [token]);

  // 새 토큰 요청 함수
  const requestNewToken = async () => {
    if (Object.keys(centerInfo).length !== 0) {
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };


  /* 리모컨 fullScreen 축소 */
  const keyboard = KeyboardDebugger();

  useEffect(() => {
    if (keyboard === "Backspace") {
      setIsFullScreen && setIsFullScreen(false);
    }
  }, [keyboard]);


  /* 레이아웃 컨트롤 버튼 show/hidden */
  useEffect(() => {
    // Show the LayoutButton initially for 5 seconds
    setShowLayoutButton(true);
    const initialTimer = setTimeout(() => {
      setShowLayoutButton(false);
    }, 5000);

    let timer;
    const handleRemoteControlInput = () => {
      setShowLayoutButton(true);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setShowLayoutButton(false);
      }, 5000);
    };

    window.addEventListener("keydown", handleRemoteControlInput);
    return () => {
      window.removeEventListener("keydown", handleRemoteControlInput);
      if (timer) {
        clearTimeout(timer);
      }
      clearTimeout(initialTimer);
    };
  }, []);

  return (
    <>
      {/*{renderComponent()}*/}
      <Conf_custom token={token} selectedLayout={selectedLayout}/>
      <LayoutButton show={showLayoutButton}>
        <LayoutItem ref={firstLayoutRef} focused={firstLayoutFocused}>
          <img
            src={selectedLayout === "first" ? FirstActiveSVG : FirstSVG}
            alt="first-layout"
          />
        </LayoutItem>
        <LayoutItem ref={secondLayoutRef} focused={secondLayoutFocused}>
          <img
            src={selectedLayout === "second" ? SecondActiveSVG : SecondSVG}
            alt="second-layout"
          />
        </LayoutItem>
        <LayoutItem ref={thirdLayoutRef} focused={thirdLayoutFocused}>
          <img
            src={selectedLayout === "third" ? ThirdActiveSVG : ThirdSVG}
            alt="third-layout"
          />
        </LayoutItem>
      </LayoutButton>
      <FullScreenButton
        ref={ref}
        focused={focused}
        onClick={() => {
          // console.log("FullScreenButton Clicked");
          setIsFullScreen(!isFullScreen);
        }}
      >
        <img
          tabIndex={0}
          src={isFullScreen ? ScaleReductionSVG : ScaleExpandingSVG}
          // onClick={() => setIsFullscreen(!isFullScreen)}

          alt={"scale"}
          className={""}
        />
      </FullScreenButton>
    </>
  );
}

Conf_screen.propTypes = {};

export default Conf_screen;
