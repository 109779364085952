import React, { useEffect } from "react";
import {
  useFocusable,
  FocusContext,
  setFocus,
  updateAllLayouts,
} from "@noriginmedia/norigin-spatial-navigation";
import styled from "styled-components";
import PopupButton from "./PopupButton";
import { Col, Row } from "react-bootstrap";
import NoticeSVG from "../../../assets/common/notice.svg";
import BellSVG from "../../../assets/common/bell.svg";
import CallSVG from "../../../assets/common/call.svg";
import ConnectSVG from "../../../assets/common/connect.svg";

const PopupWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  ${(props) =>
    props.isCentered
      ? `
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `
      : `
        left: 50%;
        bottom: 5vh;
        transform: translateX(-50%);
      `}
  width: 85%;
  height: 15vh;
  z-index: 100;
  //display: flex;
  //flex-direction: row;
  background-color: #fff;
  border-radius: 16px;
  padding: 1rem;
  margin: 0 auto;
  //align-items: center;
  //justify-content: center;
  align-content: center;
`;

function Popup({ show, onCancel, onConfirm, type, text, popupKey }) {
  const {
    ref,
    focusKey,
    focusSelf,
    hasFocusedChild
  } = useFocusable({
    focusKey: popupKey,
    focusable: true,
    isFocusBoundary: true,
    focusBoundaryDirections: ["up", "down", "left", "right"],
    trackChildren: false,
    preferredChildFocusKey: undefined,
  });

  useEffect(() => {
    if (show) {
      focusSelf(); // 현재 Popup이 열릴 때 자동 포커스 이동
    }
  }, [show, focusSelf]);

  return (
    <>
      {show ? (
        // <FocusContext.Provider value={show ? focusKey : null}>
        <FocusContext.Provider value={focusKey}>
          <PopupWrapper>
            <ButtonWrapper
              ref={ref}
              hasFocusedChild={hasFocusedChild}
              isCentered={type === "connect"}
            >
              <Row>
                <Col xs={1} className={"my-auto"}>
                  {type === "notice" && (
                    <img src={NoticeSVG} alt={"notice"} className={"w-100"} />
                  )}
                  {type === "message" && (
                    <img src={BellSVG} alt={"bell"} className={"w-100"} />
                  )}
                  {type === "call" && (
                    <img src={CallSVG} alt={"call"} className={"w-100"} />
                  )}
                  {type === "connect" && (
                    <img src={ConnectSVG} alt={"connect"} className={"w-100"} />
                  )}
                </Col>
                <Col xs={8} className={"my-auto"}>
                  {type === "notice" && (
                    <h1 className={"text-black fw-bold"}>공지사항</h1>
                  )}
                  {type === "message" && (
                    <h1 className={"text-black fw-bold"}>알림</h1>
                  )}
                  {type === "call" && (
                    <h1 className={"text-black fw-bold"}>알림</h1>
                  )}
                  {type === "connect" && (
                    <h1 className={"text-black fw-bold"}>알림</h1>
                  )}

                  <p className={"fs-3 text-black"}>
                    {/*[인천광역시 일자리사업본부] 시니어 클럽 지원 및 노인 일자리 사업 안전 교육을 안내드립니다.*/}
                    {text}
                  </p>
                </Col>
                <Col xs={3} className={"my-auto"}>
                  <Row className={"justify-content-center"}>
                    <Col xs={5}>
                      <PopupButton
                        // focusKey={'BUTTON_PRIMARY'}
                        focusKey={`${popupKey}_BUTTON_PRIMARY`}
                        name={"button1"}
                        label={type === "connect" ? "취소" : "닫기"}
                        onSelect={onCancel}
                      />
                    </Col>
                    {type !== "notice" && (
                      <Col xs={5}>
                        <PopupButton
                          // focusKey={'BUTTON_SECONDARY'}
                          focusKey={`${popupKey}_BUTTON_SECONDARY`}
                          name={"button2"}
                          label={type === "connect" ? "연결" : "확인"}
                          onSelect={onConfirm}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </ButtonWrapper>
          </PopupWrapper>
        </FocusContext.Provider>
      ) : null}
    </>
  );
}

export default Popup;
