import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SelectedMessage from "./SelectedMessage";
import BlurPng from "../../../assets/common/blurImg.png";
import { messageList } from "../../../api/apiClient";
import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import MessageItem from "./MessageItem";
import LoadingSpinner from "../../Layout/LoadingSpinner";

const MessageList = ({ centerInfo, focusKey: focusKeyParam }) => {
  const { ref, focused, focusKey } = useFocusable({
    // focusKey: "MESSAGELIST",
    focusKey: focusKeyParam || "MESSAGELIST",
  });

  const [messages, setMessages] = useState();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [previousFocus, setPreviousFocus] = useState(null); // 이전 포커스 저장
  const [isLoading, setIsLoading] = useState(false);

  const handleMessageSelect = (message, itemFocusKey) => {
    setPreviousFocus(itemFocusKey);
    setSelectedMessage(message);
  };

  const handleBackToList = () => {
    setSelectedMessage(null);
    setTimeout(() => {
      if (previousFocus) {
        setFocus(previousFocus);
      }
    }, 0);
  };

  // 메세지 get
  const getMessageList = () => {
    setIsLoading(true);

    messageList(centerInfo.center_id)
      .then((res) => {
        // console.log("messageList res : ", res.data);
        setMessages(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getMessageList();
  }, []);

  // 포커스 될 때 스크롤 이벤트
  const scrollContainerRef = useRef(null);
  const lastFocusedY = React.useRef(0);

  const onRowFocus = React.useCallback(
    ({ y }) => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        // 방향키 이동 시 포커스가 변경된 후 `y` 값을 가져오도록 딜레이 추가
        requestAnimationFrame(() => {
          let scrollTo = y - containerHeight / 2;
          scrollTo = Math.max(
            0,
            Math.min(scrollTo, scrollHeight - containerHeight)
          );

          if (lastFocusedY.current !== scrollTo) {
            container.scrollTo({
              top: scrollTo,
              behavior: "instant",
            });
            lastFocusedY.current = scrollTo;
          }
        });
      }
    },
    [scrollContainerRef]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // 메시지 리스트
  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref}>
        {!selectedMessage && (
          <div
            className="py-3 mt-5 w-100"
            style={{
              // height: "100%",
              overflow: "hidden",
            }}
          >
            {/* 헤더 */}
            <div
              className="d-flex fs-2 px-5 fw-bold text-center py-4 custom-border-secondary zIndex-1"
              style={{
                backgroundColor: "#2D2D2D",
                boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
              }}
            >
              <Col xs={1} className="border-end custom-border-secondary">
                순번
              </Col>
              <Col xs={2} className="border-end custom-border-secondary">
                어르신 성함
              </Col>
              <Col xs={2} className="border-end custom-border-secondary">
                발신자 명
              </Col>
              <Col xs={2} className="border-end custom-border-secondary">
                수신일시
              </Col>
              <Col xs={5} className="text-start ps-4">
                메세지 제목
              </Col>
            </div>
            {/* 메시지 목록 */}
            <div
              ref={scrollContainerRef}
              style={{
                maxHeight: "calc(100vh - 16rem)",
                overflowX: "hidden",
                overflowY: "auto",
                // scrollbarWidth: "auto",
                zIndex: 1,
              }}
            >
              {messages &&
                messages.map((message) => (
                  <MessageItem
                    key={message.id}
                    message={message}
                    handleMessageSelect={handleMessageSelect}
                    onFocus={onRowFocus}
                  />
                ))}

              {messages && messages.length === 0 && (
                <Row className="fs-2 text-center py-4 border-bottom custom-border-secondary">
                  <Col>메시지가 없습니다.</Col>
                </Row>
              )}
            </div>
          </div>
        )}

        {selectedMessage && (
          <SelectedMessage
            selectedMessage={selectedMessage}
            handleBackToList={handleBackToList}
          />
        )}
      </div>
    </FocusContext.Provider>
  );
};

export default MessageList;
