import { Button, Col } from "react-bootstrap";
import AllSvg from "../../../assets/video/icon-all.svg";
import AllActiveSvg from "../../../assets/video/icon-all_active.svg";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";

const BoroughButton = ({ borough, handleBoroughSelection }) => {
  const { ref, focused, focusKey } = useFocusable({
    autoFocus: true,
    focusKey: borough.name,
    onEnterPress: () => handleBoroughSelection(borough.name),
  });

  return (
    <FocusContext.Provider value={focusKey}>
      <Col xs={4} style={{ height: "14.5rem" }} ref={ref}>
        <Button
          focused={focused}
          //   tabIndex={0}
          className={`h-100 w-100 center-btn`}
          style={{
            backgroundColor: focused ? "#3FB5EC" : "black",
            //   backgroundColor: borough.name === activeBorough ? "#3FB5EC" : "black",
            border: "none",
            cursor: "pointer",
          }}
          // onClick={() => handleBoroughSelection(borough.name)}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter" || e.key === " ") {
          //     handleBoroughSelection(borough.name);
          //   }
          // }}
        >
          {borough.name === "all" ? (
            <div className="d-flex justify-content-center gap-3">
              <span className={"my-auto text-center fs-1"}>전체보기</span>
              <img src={focused ? AllActiveSvg : AllSvg} alt="all-icon" />
            </div>
          ) : (
            <span className={"my-auto text-center fs-1"}>{borough.name}</span>
          )}
        </Button>
      </Col>
    </FocusContext.Provider>
  );
};

export default BoroughButton;
