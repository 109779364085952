import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import IconCamera from "../../assets/icons/icon-camera.png";
import IconMicrophone from "../../assets/icons/icon-mic.png";
import IconCameraOff from "../../assets/icons/icon-camera-off.png";
import IconMicrophoneOff from "../../assets/icons/icon-mic-off.png";
import Conf_sender from "../Content/conf/conf_sender";
import Webcam from "react-webcam";
import { getJWT, putBroadcastStatus } from "../../api/apiClient";

const Broadcast = () => {
  const [peerConnection, setPeerConnection] = useState(null);
  const [dataChannel, setDataChannel] = useState(null);
  const localConnection = useRef(null);
  const remoteConnection = useRef(null);

  useEffect(() => {
    const servers = null; // Use default STUN servers
    const localPeerConnection = new RTCPeerConnection(servers);
    const remotePeerConnection = new RTCPeerConnection(servers);

    const dataChannel = localPeerConnection.createDataChannel("broadcastData");
    setDataChannel(dataChannel);

    localPeerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        remotePeerConnection.addIceCandidate(event.candidate);
      }
    };

    remotePeerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        localPeerConnection.addIceCandidate(event.candidate);
      }
    };

    remotePeerConnection.ondatachannel = (event) => {
      const receiveChannel = event.channel;
      receiveChannel.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        localStorage.setItem("broadcastData", JSON.stringify(newData));
        // Handle the new data as needed
      };
    };

    // Handle incoming data channel messages
    remotePeerConnection.ondatachannel = (event) => {
      const receiveChannel = event.channel;
      receiveChannel.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        if (newData.action === "toggleMute") {
          // Mute/unmute remote audio tracks
          const remoteAudioTracks = remoteConnection.current
            .getReceivers()
            .map((receiver) => receiver.track)
            .filter((track) => track.kind === "audio");
          remoteAudioTracks.forEach((track) => {
            track.enabled = !newData.mute;
          });
        }
        localStorage.setItem("broadcastData", JSON.stringify(newData));
        // Handle the new data as needed
      };
    };

    localPeerConnection
      .createOffer()
      .then((offer) => localPeerConnection.setLocalDescription(offer))
      .then(() =>
        remotePeerConnection.setRemoteDescription(
          localPeerConnection.localDescription
        )
      )
      .then(() => remotePeerConnection.createAnswer())
      .then((answer) => remotePeerConnection.setLocalDescription(answer))
      .then(() =>
        localPeerConnection.setRemoteDescription(
          remotePeerConnection.localDescription
        )
      );

    localConnection.current = localPeerConnection;
    remoteConnection.current = remotePeerConnection;

    setPeerConnection(localPeerConnection);

    return () => {
      localPeerConnection.close();
      remotePeerConnection.close();
    };
  }, []);

  const [isDisabledStart, setIsDisabledStart] = useState(false); // 버튼 비활성화
  const [isDisabledStop, setIsDisabledStop] = useState(false); // 버튼 비활성화
  const [isDisabledStreaming, setIsDisabledStreaming] = useState(false); // 버튼 비활성화
  const [isDisabledIPTV, setIsDisabledIPTV] = useState(false); // 버튼 비활성화

  const [isStreamingProcessing, setIsStreamingProcessing] = useState(false);
  const [isIPTVProcessing, setIsIPTVProcessing] = useState(false);

  const handleStreamingClick = () => {
    if (isDisabledStreaming) return; // 클릭 방지

    setIsDisabledStreaming(true);
    setIsDisabledIPTV(true);
    setIsDisabledStart(true);
    setIsDisabledStop(true);

    // "전환 중" 표시
    setIsStreamingProcessing(true);

    console.log("handleStreamingClick");
    const status = "streaming";

    const updateStatus = (currentStatus) => {
      console.log("updateStatus currentStatus");

      putBroadcastStatus(currentStatus)
        .then((res) => {
          console.log("putBroadcastStatus res : ", res.data);
          if (currentStatus === "streaming") {
            setTimeout(() => {
              updateStatus("free");

              setTimeout(() => {
                setIsDisabledIPTV(false);
                setIsDisabledStart(false);
                setIsDisabledStop(false);
                setIsDisabledStreaming(false);
                setIsStreamingProcessing(false);
              }, 3000);
            }, 11000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    updateStatus(status);
  };

  const handleIPTVClick = () => {
    if (isDisabledIPTV) return; // 클릭 방지

    setIsDisabledStart(true);
    setIsDisabledStop(true);
    setIsDisabledStreaming(true);
    setIsDisabledIPTV(true);

    // "전환 중" 표시
    setIsIPTVProcessing(true);

    console.log("handleIPTVClick");
    const status = "iptv";

    const updateStatus = (currentStatus) => {
      console.log("updateStatus currentStatus");

      putBroadcastStatus(currentStatus)
        .then((res) => {
          console.log("putBroadcastStatus res : ", res.data);
          if (currentStatus === "iptv") {
            setTimeout(() => {
              updateStatus("free");

              setTimeout(() => {
                setIsDisabledStart(false);
                setIsDisabledStop(false);
                setIsDisabledStreaming(false);
                setIsDisabledIPTV(false);
                setIsIPTVProcessing(false);
              }, 3000);
            }, 11000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    updateStatus(status);
  };

  const [token, setToken] = useState("");

  useEffect(() => {
    getJWT("스튜디오")
      .then((res) => {
        setToken(res.data.token.slice(2, -1));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    console.log("token: ", token);
  }, [token]);

  const [options, setOptions] = useState({
    device: "1",
    camera: true,
    microphone: true,
  });

  useEffect(() => {
    console.log("options: ", options);
  }, [options]);

  const [renderBroadCast, setRenderBroadCast] = useState(false);

  const handleDevice = (e) => {
    setOptions({ ...options, device: e.target.value });
  };

  const [devices, setDevices] = useState([]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
      setOptions({
        ...options,
        device:
          mediaDevices.filter(({ kind }) => kind === "videoinput")[0]
            ?.deviceId || "",
      });
      console.log(mediaDevices);
    },
    [setDevices]
  );

  React.useEffect(() => {
    if (
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.enumerateDevices
    ) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    } else {
      console.error("enumerateDevices is not supported");
    }
  }, [handleDevices]);

  const handleStart = (e) => {
    // Start broadcasting
    e.preventDefault();

    if (isDisabledStart) return;

    console.log("Start broadcasting");
    setRenderBroadCast(true);
    handleStreamingClick();

    // 버튼
    setIsDisabledStart(true);
    setIsDisabledStreaming(true);
    setIsDisabledIPTV(true);

    setIsDisabledStop(true);
    setIsStreamingProcessing(false);

    setTimeout(() => {
      setIsDisabledStart(false);
      setIsDisabledStop(false);
      setIsDisabledStreaming(false);
      setIsDisabledIPTV(false);
    }, 15000);
  };

  const handleStop = (e) => {
    e.preventDefault();

    console.log("Stop broadcasting");
    setRenderBroadCast(false);
    const status = "free";
    putBroadcastStatus(status)
      .then((res) => {
        console.log("putBroadcastStatus res : ", res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleToggleMute = () => {
    // 로컬 오디오 트랙 mute/unmute
    const localAudioTracks = localConnection.current
      .getSenders()
      .map((sender) => sender.track)
      .filter((track) => track.kind === "audio");
    localAudioTracks.forEach((track) => {
      track.enabled = !track.enabled;
    });

    // 모든 원격 참가자들에게 mute/unmute 명령 전송
    const newData = { action: "toggleMute", mute: !options.microphone };
    if (dataChannel && dataChannel.readyState === "open") {
      dataChannel.send(JSON.stringify(newData));
    }

    setOptions({ ...options, microphone: !options.microphone });
  };

  return (
    <Container fluid className={"h-100"} style={{ background: "#2c2c2c" }}>
      <Row style={{ background: "#2c2c2c" }}>
        <Col xs={12} className={"p-5"}>
          <Row className={"row-gap-5"}>
            <Col
              xs={10}
              className={"border"}
              style={{ aspectRatio: "16/9", background: "#2c2c2c" }}
            >
              {renderBroadCast ? (
                <>
                  <Conf_sender token={token} />
                </>
              ) : (
                <>
                  <div
                    className={
                      "d-flex justify-content-center align-items-center my-5 "
                    }
                  >
                    <h3>방송 송출 준비중입니다.</h3>
                    <h6>(ver. 1.3.2090)</h6>
                  </div>
                  <Row className={"justify-content-start"}>
                    {devices.map((device, key) => (
                      <Col
                        xs={12}
                        md={6}
                        lg={4}
                        key={key}
                        className={"text-center my-2"}
                      >
                        <Webcam
                          audio={false}
                          videoConstraints={{ deviceId: device.deviceId }}
                          style={{ width: "100%" }}
                        />
                        <h4>{device.label || `Device ${key + 1}`}</h4>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Col>
            <Col xs={2}>
              <Form>
                <Row className={"row-gap-3 justify-content-center"}>
                  <Col xs={11}>
                    <h3>장치 선택</h3>
                  </Col>
                  <Col xs={11}>
                    <Form.Select
                      aria-label="Default select example"
                      value={options.device}
                      onChange={handleDevice}
                      size={"lg"}
                    >
                      {devices.map((device, key) => (
                        <option key={key} value={device.deviceId}>
                          {device.label || `Device ${key + 1}`}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row className={"row-gap-3 justify-content-center mt-5"}>
                  <Col xs={11}>
                    <h3>권한 선택</h3>
                  </Col>
                  <Col xs={5} className={"text-center"}>
                    <img
                      src={options.camera ? IconCamera : IconCameraOff}
                      alt={"preview"}
                      className={"w-50"}
                      style={{ opacity: 0.3 }}
                    />
                  </Col>
                  <Col xs={5} className={"text-center"}>
                    <img
                      src={
                        options.microphone ? IconMicrophone : IconMicrophoneOff
                      }
                      alt={"preview"}
                      className={"w-50"}
                      style={{ opacity: 0.3 }}
                      onClick={handleToggleMute}
                    />
                  </Col>
                </Row>

                <Row className={"row-gap-3 justify-content-center mt-5"}>
                  <Col xs={11}>
                    <h3>방송 송출</h3>
                  </Col>
                  <Col xs={11}>
                    <Button
                      className={`btn btn-primary w-100 ${
                        isDisabledStart ? "disabled" : ""
                      } `}
                      onClick={handleStart}
                    >
                      시작
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"secondary"}
                      className={`btn w-100 ${
                        isDisabledStop ? "disabled" : ""
                      }`}
                      onClick={handleStop}
                    >
                      중지
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"danger"}
                      className={`btn w-100 ${
                        isDisabledStreaming ? "disabled" : ""
                      }`}
                      onClick={handleStreamingClick}
                    >
                      {isStreamingProcessing
                        ? " 스트리밍 전환 중"
                        : "스트리밍 전환"}
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"danger"}
                      className={`btn w-100 ${
                        isDisabledIPTV ? "disabled" : ""
                      }`}
                      onClick={handleIPTVClick}
                    >
                      {isIPTVProcessing ? " IPTV 전환 중" : "IPTV 전환"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Broadcast;
