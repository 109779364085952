import React, { useEffect } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";

const handleJaaSIFrameRef = (iframeRef) => {
  iframeRef.style.border = "0px solid #3d3d3d";
  iframeRef.style.background = "#3d3d3d";
  iframeRef.style.height = "890px";
};

// ISC App ID
var YOUR_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd";

function Conf_sender({ token }) {
  useEffect(() => {
    console.log("token: ", token);
  }, [token]);

  return (
    <JaaSMeeting
      appId={YOUR_APP_ID}
      roomName={"icsc-stream-prod"}
      jwt={token}
      configOverwrite={{
        startWithAudioMuted: false,
        disableLocalVideoFlip: true,
        doNotFlipLocalVideo: true,
        prejoinPageEnabled: false,
        disableAP: true,
      }}
      getIFrameRef={handleJaaSIFrameRef}
    />
  );
}

Conf_sender.propTypes = {};

export default Conf_sender;
