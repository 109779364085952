import React from "react";
import { Row, Spinner } from "react-bootstrap";

const LoadingSpinner = ({ type }) => {
  if (type === "streaming") {
    return (
      <Row className="d-flex flex-column justify-content-center align-items-center h-100 text-center text-white">
        <Spinner
          animation="border"
          variant="light"
          className="mb-5"
          style={{ width: "3rem", height: "3rem" }}
        />
        <p className="mb-0 fs-4">잠시만 기다려주세요.</p>
      </Row>
    );
  }

  if (type === "slide") {
    return (
      <Row className="d-flex flex-column justify-content-center align-items-center h-100 text-center text-white">
        <Spinner
          animation="border"
          variant="light"
          style={{ width: "3rem", height: "3rem" }}
        />
      </Row>
    );
  }

  return (
    <Row className="d-flex flex-column justify-content-center align-items-center vh-100 text-center text-white">
      <Spinner
        animation="border"
        variant="light"
        className="mb-5"
        style={{ width: "3rem", height: "3rem" }}
      />
      <p className="mb-0 fs-4">잠시만 기다려주세요.</p>
    </Row>
  );
};

export default LoadingSpinner;
