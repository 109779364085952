import HomeSVG from "../assets/menus/icon-home.svg";
import BroadCastSVG from "../assets/menus/icon-broadcast.svg";
import MessageSVG from "../assets/menus/message.svg";
import AdditionalSVG from "../assets/menus/icon-additional.svg";
import StreamingSVG from "../assets/menus/streaming.svg";
import MeetingSVG from "../assets/menus/icon-meeting.svg";
import IptvSVG from "../assets/menus/tv.svg";
import WeatherSVG from "../assets/menus/icon-weather.svg";
import BusSVG from "../assets/menus/bus.svg";
import MessageListSVG from "../assets/menus/icon-message-list.svg";
import VideoCallSVG from "../assets/menus/call.svg";
import InfoSVG from "../assets/menus/icon-info.svg";

// hooks/RenderIcon.js
export const renderIcon = (menu) => {
  // console.log("menuName: " + menuName);
  // console.log("menu: " + JSON.stringify(menu));

  switch (menu) {
    case "home":
      return HomeSVG;
    case "broadcast":
      return BroadCastSVG;
    case "message":
      return MessageSVG; // o
    case "messageList":
      return MessageListSVG;
    case "videoCall":
      return VideoCallSVG; // o
    case "additional":
      return AdditionalSVG;
    case "streaming":
      return StreamingSVG; // o
    case "meeting":
      return MeetingSVG;
    case "iptv":
      return IptvSVG; // o
    case "weather":
      return WeatherSVG;
    case "busArrived":
      return BusSVG; // o
    case "info":
      return InfoSVG;
    default:
      return HomeSVG;
  }
};
