import SelectedMessage from "../../Content/message/SelectedMessage";
import CloseSvg from "../../../assets/common/icon-close.svg";
import styled from "styled-components";
import {
  useFocusable,
  FocusContext,
  setFocus,
  updateAllLayouts,
} from "@noriginmedia/norigin-spatial-navigation";
import {useEffect} from "react";
import MessagePopupButton from "./MessagePopupButton";
import MessagePopupContent from "./MessagePopupContent";
import {Col, Row} from "react-bootstrap";

const PopupWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
`;

const MessageWrapper = styled.div`
    position: relative;
    left: 50%;
    top: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    //border: 3px solid #fff;
    background-color: #171717;
    border-radius: 30px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 1%;
    right: 1%;
    z-index: 99;
    // border: ${(props) => (props.focused ? "3px solid white" : "none")};
`;


const MessagePopup = ({show, onClose, popupKey, message}) => {
  const {
    ref,
    focusKey,
    focusSelf,
    hasFocusedChild,
  } = useFocusable({
    focusKey: popupKey,
    focusable: true,
    isFocusBoundary: true,
    focusBoundaryDirections: ["up", "down", "left", "right"],
    trackChildren: false,
    preferredChildFocusKey: undefined,
  });

  useEffect(() => {
    if (show) {
      focusSelf(); // 현재 Popup이 열릴 때 자동 포커스 이동
    }
  }, [show, focusSelf]);

  return (
    <>
      {show ? (
        <FocusContext.Provider value={focusKey}>
          <PopupWrapper>
            <MessageWrapper
              ref={ref}
              hasFocusedChild={hasFocusedChild}
            >
              <MessagePopupContent selectedMessage={message}/>

              <ButtonWrapper>
                <MessagePopupButton
                  focusKey={`MESSAGE_CLOSE`}
                  label={"닫기"}
                  onSelect={onClose}
                />
              </ButtonWrapper>
            </MessageWrapper>
          </PopupWrapper>
        </FocusContext.Provider>
      ) : null}
    </>
  );
};

export default MessagePopup;
