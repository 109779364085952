import {
  FocusContext,
  getCurrentFocusKey,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
// import React, { useEffect, useRef } from "react";
import {Col, Row} from "react-bootstrap";

const MessageItem = ({message, handleMessageSelect, onFocus}) => {
  const {ref, focused, focusKey} = useFocusable({
    focusKey: `message-item-${message.id}`,
    // onEnterPress: () => handleMessageSelect(message),
    onFocus: ({y}) => onFocus({y}),
    onEnterPress: () => {
      // 현재 포커스 키 저장 후 handleMessageSelect 호출
      const currentFocusKey = getCurrentFocusKey();
      handleMessageSelect(message, currentFocusKey);
    },
  });

  // const rowRef = useRef(null);

  // // 포커스가 변경되었을 때, 자동으로 해당 요소로 스크롤 이동
  // useEffect(() => {
  //   if (focused && rowRef.current) {
  //     rowRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest", // 요소가 스크롤 컨테이너에서 벗어나지 않도록 설정
  //     });
  //   }
  // }, [focused]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        className="fs-2 text-center align-items-center mx-4 py-3 border-bottom custom-border-secondary"
        // ref={(el) => {
        //   ref.current = el; // `useFocusable()`의 ref 적용
        //   rowRef.current = el; // 스크롤 처리를 위한 ref 적용
        // }}
      >
        <Row
          ref={ref}
          focused={focused}
          style={{
            backgroundColor: focused ? "rgba(63,181,236,0.4)" : "",
            borderRadius: "8px",
            margin: "0 0.5rem",
            padding: "0.5rem",
            transition: "all 0.3s ease",
          }}
        >
          <Col xs={1}>{message.id}</Col>
          <Col xs={2}>{message.senior_name}</Col>
          <Col xs={2}>{message.author}</Col>
          <Col xs={2}>
            {new Date(message.updated_at)
              .toLocaleDateString("ko-KR", {
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/\.$/, "")}
          </Col>
          <Col
            xs={5}
            // tabIndex={0}
            className="text-start ps-4 text-truncate"
            // onClick={() => {
            //   handleMessageSelect(message);
            // }}
            // onKeyDown={(event) => {
            //   if (event.key === "Enter") {
            //     handleMessageSelect(message);
            //   }
            // }}
            style={{cursor: "pointer"}}
          >
            {message.title}
          </Col>
        </Row>
      </div>
    </FocusContext.Provider>
  );
};

export default MessageItem;
