import {useEffect, useState} from "react";

const KeyboardDebugger = () => {
  const [lastKeyPressed, setLastKeyPressed] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`);
      setLastKeyPressed(event.key);

      if (event.key === "BrowserBack") {
        event.preventDefault();

        /*const prevBtn = document.getElementsByClassName("prev")[0];
        console.log("prevBtn: ", prevBtn);

        if (prevBtn) {
          prevBtn.click();
        }*/
      }
    };

    const handleClick = (event) => {
      console.log(`Mouse clicked at: (${event.clientX}, ${event.clientY})`);
    };

    window.addEventListener("keydown", handleKeyDown);
    // window.addEventListener("keypress", handleKeyDown);
    // window.addEventListener("keyup", handleKeyDown);
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      // window.addEventListener("keypress", handleKeyDown);
      // window.addEventListener("keyup", handleKeyDown);
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return lastKeyPressed;
};

export default KeyboardDebugger;