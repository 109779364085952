import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./VideoCall.scss";
import Call_screen from "../conf/call_screen";
import { getJitsiCenterList, getJWT } from "../../../api/apiClient";
import SeniorCenter from "./SeniorCenter";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import BoroughButton from "./BoroughButton";
import LoadingSpinner from "../../Layout/LoadingSpinner";

const VideoCall = ({
  centerInfo,
  deviceId,
  videoCallInfo,
  focusKey: focusKeyParam,
  isCallAccepted,
}) => {
  const { ref, focusKey } = useFocusable({
    focusKey: focusKeyParam,
    autoFocus: true,
  });

  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(centerInfo).length !== 0) {
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [centerInfo]);

  const [activeMenu, setActiveMenu] = useState("boroughSelection");
  const [boroughList, setBoroughList] = useState([]);
  const [activeBorough, setActiveBorough] = useState("all");

  const getBoroughList = () => {
    setIsLoading(true);

    if (!deviceId) return;
    getJitsiCenterList(deviceId)
      .then((res) => {
        console.log(res.data);
        setBoroughList(res.data.boroughs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (deviceId) {
      getBoroughList();
    }
  }, [deviceId]);

  const handleBoroughSelection = (borough) => {
    setActiveMenu("seniorCenter");
    setActiveBorough(borough);
  };
  const [newToken, setNewToken] = useState("");
  const [newRoomname, setNewRoomname] = useState("");

  useEffect(() => {
    if (videoCallInfo) {
      getJWT(centerInfo.name)
        .then((res) => {
          setNewToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [videoCallInfo]);

  let callScreenComponent = null;

  if (videoCallInfo && newToken && isCallAccepted) {
    callScreenComponent = (
      // eslint-disable-next-line react/jsx-pascal-case
      <Call_screen
        token={newToken}
        roomname={videoCallInfo?.title}
        setToken={setNewToken}
        centerInfo={centerInfo}
      />
    );
  } else if (token && activeMenu === "communityCenterVideoCall") {
    callScreenComponent = (
      // eslint-disable-next-line react/jsx-pascal-case
      <Call_screen
        token={token}
        roomname={newRoomname}
        setToken={setToken}
        centerInfo={centerInfo}
      />
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      {callScreenComponent}
      {!(videoCallInfo && newToken) && (
        <>
          <Row className="p-4 row-gap-4 overflow-y-auto" ref={ref}>
            {token &&
              activeMenu === "boroughSelection" &&
              boroughList.map((borough, idx) => (
                <BoroughButton
                  key={idx}
                  borough={borough}
                  handleBoroughSelection={handleBoroughSelection}
                />
              ))}
          </Row>

          {token && activeMenu === "seniorCenter" && (
            <SeniorCenter
              activeBorough={activeBorough}
              boroughList={boroughList}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              token={token}
              centerInfo={centerInfo}
              setToken={setToken}
              setNewRoomname={setNewRoomname}
            />
          )}
        </>
      )}
    </FocusContext.Provider>
  );
};

export default VideoCall;
