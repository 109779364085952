import React, { useEffect } from "react";
import Webcam from "react-webcam";
import styled from "styled-components";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import ScaleReductionSVG from "../../../assets/broadcast/scale-reduction.svg";
import ScaleExpandingSVG from "../../../assets/broadcast/scale-expanding.svg";
import KeyboardDebugger from "../../../hooks/KeyboardDebugger";

const FullScreenButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
  border: ${(props) => (props.focused ? "3px solid white" : "none")};
`;

function Capture({
  isFullScreen,
  setIsFullScreen,
  // focusKey: focusKeyParam
}) {
  const { ref, focused, focusKey } = useFocusable({
    focusKey: "fullScreen",
    autoFocus: true,
    onEnterPress: () => {
      setIsFullScreen(!isFullScreen);
    },
  });

  // Webcam 장비 강제 인식으로 Captureboard 연결할 예정
  const [allDevices, setAllDevices] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const [aiDevice, setAIDevice] = React.useState([]);
  const [aoDevice, setAODevice] = React.useState([]);
  const [viDevice, setVIDevice] = React.useState([]);
  const videoRef = React.useRef();

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      // 실제 장치만 리스팅
      setDevices(
        mediaDevices.filter(
          ({ deviceId }) =>
            deviceId !== "default" && deviceId !== "communications"
        )
      );
      setAllDevices(mediaDevices);
      console.log(mediaDevices);
    },
    [setDevices]
  );

  React.useEffect(() => {
    if (
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.enumerateDevices
    ) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    } else {
      console.error("enumerateDevices is not supported by this browser.");
    }
  }, [handleDevices]);

  React.useEffect(() => {
    console.log(devices);
    // Filtering logic
    devices.forEach((device) => {
      if (
        device.kind === "videoinput" &&
        device.label.includes("Live Streamer CAP 4K")
      ) {
        setVIDevice(device);
      } else if (
        device.kind === "audioinput" &&
        device.label.includes("Live Streamer CAP 4K")
      ) {
        setAIDevice(device);
      } else if (
        device.kind === "audiooutput" &&
        (device.label.includes("HDMI") ||
          device.label.includes("High Definition"))
      ) {
        setAODevice(device);
      }
    });
  }, [devices]);

  React.useEffect(() => {
    if (videoRef !== null && aoDevice !== null) {
      const newVideo = videoRef.current?.video;
      console.log(newVideo);
      if (newVideo) {
        if (newVideo?.sinkId !== undefined) {
          newVideo.setSinkId(aoDevice.deviceId);
        }
      }
    }
    console.log(videoRef);
  }, [aoDevice, videoRef]);

  /* 리모컨 fullScreen 축소 */
  const keyboard = KeyboardDebugger();

  useEffect(() => {
    if (keyboard === "Backspace") {
      setIsFullScreen && setIsFullScreen(false);
    }
  }, [keyboard]);

  return (
    <>
      {viDevice?.kind !== "videoinput" ||
      aiDevice?.kind !== "audioinput" ||
      aoDevice?.kind !== "audiooutput" ? (
        <div>
          <br />
          <h3>오류</h3>
          <ul>
            <li>"장치가 정상적으로 인식되지 않았습니다."</li>
          </ul>
          <h3>장비정보</h3>
          <ul>
            <li>
              비디오 입력:{" "}
              {`Kind: ${viDevice?.kind}, Label: ${viDevice?.label}, Device ID: ${viDevice?.deviceId}`}{" "}
            </li>
            <li>
              오디오 입력:{" "}
              {`Kind: ${aiDevice?.kind}, Label: ${aiDevice?.label}, Device ID: ${aiDevice?.deviceId}`}{" "}
            </li>
            <li>
              오디오 출력:{" "}
              {`Kind: ${aoDevice?.kind}, Label: ${aoDevice?.label}, Device ID: ${aoDevice?.deviceId}`}{" "}
            </li>
          </ul>
          <h3>Device List</h3>
          <ul>
            {allDevices.map((device, index) => (
              <li key={index}>
                {`Kind: ${device.kind}, Label: ${device.label}, Device ID: ${device.deviceId}`}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <Webcam
          className={"w-100 h-100 webcam"}
          style={{ width: "100%", height: "100%" }}
          ref={videoRef}
          audio={true}
          videoConstraints={viDevice}
          audioConstraints={aiDevice}
        ></Webcam>
      )}

      <FullScreenButton
        ref={ref}
        // focused={focused}
        focused={focused ? "true" : undefined}
        onClick={() => {
          setIsFullScreen(!isFullScreen);
        }}
      >
        <img
          tabIndex={0}
          src={isFullScreen ? ScaleReductionSVG : ScaleExpandingSVG}
          alt={"scale"}
          className={""}
        />
      </FullScreenButton>
    </>
  );
}

export default Capture;
