import {useEffect} from "react";

const BlockBackNavigation = () => {
  useEffect(() => {
    // '뒤로가기' 버튼 클릭 및 키보드 입력 막기
    const handleBackNavigation = (event) => {
      // 키보드의 Backspace 또는 Alt+Left Arrow 막기
      if (event.key === "Backspace" || (event.altKey && event.key === "ArrowLeft")) {
        event.preventDefault();
      }
    };

    // 뒤로가기 이벤트 차단
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, "", window.location.href);
    };

    // 'popstate' 이벤트를 통해 뒤로가기 막기
    window.history.pushState(null, "", window.location.href); // 현재 URL을 히스토리에 추가
    window.addEventListener("popstate", handlePopState);

    // 'Backspace' 또는 'Alt + Left Arrow' 키 입력 시 뒤로가기 방지
    window.addEventListener("keydown", handleBackNavigation);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("keydown", handleBackNavigation);
    };
  }, []);

  return;
};

export default BlockBackNavigation;
