import React, { useState, useEffect } from "react";
//import useScript from '../../hooks/useScript';
import useScript from "../../../hooks/useScript";
import "./conf_custom.css";
import CenterWait from "../../../assets/broadcast/center-wait.jpg";
import StudioWait from "../../../assets/broadcast/studio-wait.jpg";
import { jwtDecode } from "jwt-decode";

// ISC App ID
const ICSC_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd";
const ROOM_NAME = "icsc-stream-prod";

const state = {
  appId: ICSC_APP_ID,
  room: ROOM_NAME,
  jwt: undefined,
  connection: undefined,
  conference: undefined,
  username: undefined,
};

function Conf_custom({ token, selectedLayout }) {
  useEffect(() => {
    console.log("selectedLayout: ", selectedLayout);
  }, [selectedLayout]);

  const [isLoaded, setIsLoaded] = useState(false);

  useScript("https://code.jquery.com/jquery-3.5.1.min.js");
  useScript("https://8x8.vc/libs/lib-jitsi-meet.min.js", setIsLoaded);

  useEffect(() => {
    console.log("token: ", token);
    state.jwt = token;

    const decodedToken = jwtDecode(token);
    console.log("decodedToken: ", decodedToken);
    console.log("decodedTokenName", decodedToken.context.user.name); // 사용자의 이름이 출력되어야 합니다.
    state.username = decodedToken?.context?.user?.name;
  }, [token]);

  useEffect(() => {
    if (isLoaded) {
      console.log("JitsiMeetJS is loaded");
      console.log("Lib Jitsi Initialize......");

      // Initialize UI
      createInitialLayout(selectedLayout);

      // Initialize library.
      window.JitsiMeetJS.init();
      window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.INFO);
      console.log(`using LJM version ${window.JitsiMeetJS.version}!`);

      // Connect to the room.
      connect();
    }

    // Cleanup on unmount
    return () => {
      if (GM) {
        GM.clearTrack();
        GM = null;
      }
      leave();
    };
  }, [isLoaded]);

  // useEffect(() => {
  //   if (isLoaded) {
  //     console.log("Updating grid layout to size:", gridSize);
  //     updateGridLayout(gridSize);
  //   }
  // }, [gridSize, isLoaded]);

  useEffect(() => {
    updateGridLayout(selectedLayout);
  }, [selectedLayout]);

  // Cleanup on unmount.

  return (
    <div className="container-fluid h-100">
      <div id="meeting-grid" className="row h-100"></div>
    </div>
  );
}

export default Conf_custom;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Layout functions

let GM = null;

// Grid Manager class
// 랜덤으로 그리드 준다.
// 넘치면 안준다. (null)

class GridTrackManager {
  constructor() {
    this.MaxGrid = 44;
    this.cntUsedGrid = 1; // 0 is for local video
    this.isUsedGrid = new Array(this.MaxGrid).fill(false);
    this.infoGridId = new Array(this.MaxGrid).fill(null);
    this.trackList = new Array();
    this.isRandom = false;
  }

  addTrack(track) {
    console.log("GridTrackManager: addTrack - track:", track);
    this.trackList.push(track);
  }

  clearTrack() {
    console.log("GridTrackManager: clearTrack - trackList:", this.trackList);
    this.trackList = new Array();
  }

  resetGrid(isRandom) {
    this.cntUsedGrid = 1;
    this.isUsedGrid = new Array(this.MaxGrid).fill(false);
    this.infoGridId = new Array(this.MaxGrid).fill(null);
    this.isRandom = isRandom;
  }

  redrawTrack() {
    console.log("GridTrackManager: redrawTrack - trackList:", this.trackList);

    for (let i = 0; i < this.trackList.length; i++) {
      let track = this.trackList[i];
      if (track) {
        TrackAddedProc(track, false);
      }
    }
  }

  getStudioNode() {
    return document.getElementById("studio");
  }

  // get a random node
  getGridNode(vidId) {
    if (this.cntUsedGrid >= this.MaxGrid) {
      return null;
    } else {
      // find id from the list
      for (let i = 0; i < this.MaxGrid; i++) {
        if (this.infoGridId[i] === vidId) {
          return document.getElementById(vidId);
        }
      }

      console.log("GridManager: getGridNode - id not found:", vidId);

      // assign random grid
      let randId = 0;
      let videoNode = null;

      if (this.isRandom) {
        randId = Math.floor(Math.random() * this.MaxGrid);
        while (this.isUsedGrid[randId]) {
          randId = (randId + 1) % this.MaxGrid;
        }
      } else {
        randId = this.cntUsedGrid;
        while (this.isUsedGrid[randId]) {
          randId = (randId + 1) % this.MaxGrid;
        }
      }

      videoNode = document.querySelector("#placeholder-" + randId);
      console.log(
        "GridManager: getGridNode - assigned grid:",
        randId,
        videoNode
      );
      this.isUsedGrid[randId] = true;
      this.infoGridId[randId] = vidId;
      this.cntUsedGrid++;

      return videoNode;
    }
  }

  getLocalNode(vidId) {
    for (let i = 0; i < this.MaxGrid; i++) {
      if (this.infoGridId[i] === vidId) {
        return document.getElementById(vidId);
      }
    }

    // assign random grid
    let randId = 0;
    let videoNode = null;

    videoNode = document.querySelector("#placeholder-" + randId);
    console.log(
      "GridManager: getGridNode - assigned local grid:",
      randId,
      videoNode
    );
    this.isUsedGrid[randId] = true;
    this.infoGridId[randId] = vidId;

    return videoNode;
  }

  // find a node with video id

  // clean up the grid (pos) or (video id)
  cleanGrid(vidId) {
    for (let i = 0; i < this.MaxGrid; i++) {
      if (this.infoGridId[i] === vidId) {
        let videoNode;
        console.log("GridManager: cleanGrid - found id:", vidId, "at pos:", i);
        videoNode = document.getElementById(vidId);
        setBlankGrid(videoNode, i);
        this.isUsedGrid[i] = null;
        return true; // found and cleaned
      }
    }
    return false; // not found
  }
}

function createInitialLayout(selectedLayout) {
  // Clear existing grid
  const meetingGrid = document.getElementById("meeting-grid");
  meetingGrid.innerHTML = ""; // Clear existing grid

  GM = new GridTrackManager();

  // Create initial grid based on selected layout
  updateGridLayout(selectedLayout);
}

function updateGridLayout(selectedLayout) {
  if (selectedLayout === "first") {
    createFirstLayout();
    if (GM) {
      GM.resetGrid(false);
      GM.redrawTrack();
    }
  } else if (selectedLayout === "second") {
    createSecondLayout();
    if (GM) {
      GM.resetGrid(false);
      GM.redrawTrack();
    }
  } else if (selectedLayout === "third") {
    createThirdLayout();
    if (GM) {
      GM.resetGrid(false);
      GM.redrawTrack();
    }
  }
}

function createFirstLayout() {
  // temp
  createInitialGrid6b();
}

function createSecondLayout() {
  // temp
  createInitialGrid6r();
}

function createThirdLayout() {
  createInitialGrid44();
}

function setBlankGrid(videoNode, pos) {
  console.log("setBlankGrid:", videoNode, pos);
  if (videoNode) {
    videoNode.id = `placeholder-${pos}`;
    videoNode.className = "jitsiTrack h-100 "; // Default class
    videoNode.autoplay = true;
    videoNode.style.backgroundImage = `url(${CenterWait})`;
    videoNode.style.backgroundSize = "cover";
    videoNode.style.backgroundRepeat = "no-repeat";
    videoNode.style.backgroundPosition = "center";
    videoNode.style.padding = "0 !important";
  }
}

function createInitialGrid44() {
  const meetingGrid = document.getElementById("meeting-grid");
  meetingGrid.innerHTML = ""; // Clear existing grid

  const gridSize = 44;

  meetingGrid.style.gridTemplateColumns = "repeat(12, 1fr)";
  meetingGrid.style.gridTemplateRows = "repeat(12, 1fr)";

  for (let i = 0; i < gridSize; i++) {
    const videoNode = document.createElement("video");
    setBlankGrid(videoNode, i);
    meetingGrid.appendChild(videoNode);
  }

  const studioNode = document.createElement("video");
  studioNode.id = "studio";
  studioNode.className = "jitsiTrack large h-100 animate-in";
  studioNode.style.gridColumn = "1 / span 10";
  studioNode.style.gridRow = "1 / span 10";

  studioNode.autoplay = true;
  studioNode.style.backgroundImage = `url(${StudioWait})`;
  studioNode.style.backgroundSize = "cover";
  studioNode.style.backgroundRepeat = "no-repeat";
  studioNode.style.backgroundPosition = "center";
  meetingGrid.appendChild(studioNode);
}

function createInitialGrid6b() {
  const meetingGrid = document.getElementById("meeting-grid");
  meetingGrid.innerHTML = ""; // Clear existing grid

  const gridSize = 6;

  meetingGrid.style.gridTemplateColumns = "repeat(6, 1fr)";
  meetingGrid.style.gridTemplateRows = "repeat(6, 1fr)";

  for (let i = 0; i < gridSize; i++) {
    const videoNode = document.createElement("video");
    setBlankGrid(videoNode, i);
    meetingGrid.appendChild(videoNode);
  }

  const studioNode = document.createElement("video");
  studioNode.id = "studio";
  studioNode.className = "jitsiTrack large h-100 animate-in";
  studioNode.style.gridColumn = "1 / span 6";
  studioNode.style.gridRow = "1 / span 5";

  studioNode.autoplay = true;
  studioNode.style.backgroundImage = `url(${StudioWait})`;
  studioNode.style.backgroundSize = "cover";
  studioNode.style.backgroundRepeat = "no-repeat";
  studioNode.style.backgroundPosition = "center";
  meetingGrid.appendChild(studioNode);
}

function createInitialGrid6r() {
  const meetingGrid = document.getElementById("meeting-grid");
  meetingGrid.innerHTML = ""; // Clear existing grid

  const gridSize = 6;

  meetingGrid.style.gridTemplateColumns = "repeat(6, 1fr)";
  meetingGrid.style.gridTemplateRows = "repeat(6, 1fr)";

  for (let i = 0; i < gridSize; i++) {
    const videoNode = document.createElement("video");
    setBlankGrid(videoNode, i);
    meetingGrid.appendChild(videoNode);
  }

  const studioNode = document.createElement("video");
  studioNode.id = "studio";
  studioNode.className = "jitsiTrack large h-100 animate-in";
  studioNode.style.gridColumn = "1 / span 5";
  studioNode.style.gridRow = "1 / span 6";

  studioNode.autoplay = true;
  studioNode.style.backgroundImage = `url(${StudioWait})`;
  studioNode.style.backgroundSize = "cover";
  studioNode.style.backgroundRepeat = "no-repeat";
  studioNode.style.backgroundPosition = "center";
  meetingGrid.appendChild(studioNode);
}

// Create initial grid

// Handle track added
const handleTrackAdded = (track) => {
  console.log("Track object:", track);
  TrackAddedProc(track, true);
};

const TrackAddedProc = (track, isNewTrack) => {
  console.log("Track object:", track);
  // Check if the track object is valid
  if (
    !track ||
    typeof track.getType !== "function" ||
    typeof track.attach !== "function"
  ) {
    console.error("Invalid track object:", track);
    return;
  }

  const trackType = track.getType();
  console.log("Track type:", trackType);

  const paticipantId = track.getParticipantId();
  const participant = paticipantId ? track.conference.getParticipantById(paticipantId) : null;
  const displayName = participant ? participant.getDisplayName() : "";
  let studioTrackName = null;
  console.log("Participant display name:", displayName);

  if (trackType === "video") {
    console.log("Track object: video track");
    // Handle video track
    if (isNewTrack) {
      GM.addTrack(track);
    }

    if (displayName === "스튜디오") {
      studioTrackName = track.getSourceName();
      state.conference.setReceiverConstraints({
        lastN: 44, // Number of videos requested from the bridge.
        selectedSources: [studioTrackName], // The source names of the video tracks that are prioritized first.
        onStageSources: [studioTrackName], // The source names of the video tracks that are prioritized up to a higher resolution.
        defaultConstraints: { maxHeight: 720 }, // Default resolution requested for all endpoints.
        constraints: {
          // Source specific resolution.
          studioTrackName: { maxHeight: 720 },
        },
      });
    }

    state.conference.setReceiverVideoConstraint(720);
  } else if (trackType === "audio") {
    console.log("This is an audio track");
    // Handle audio track
  } else {
    console.log("Unknown track type");
  }

  const meetingGrid = document.getElementById("meeting-grid");

  if (track.getType() === "video") {
    let videoNode = null;
    let videoContainer = document.createElement("div");
    // videoContainer.className = "video-container";
    videoContainer.className = "video-container animate-in"; // 애니메이션 추가

    // 스튜디오 체크 및 비디오 노드 위치 할당
    if (displayName === "스튜디오") {
      // Create a large video node for "스튜디오"
      //videoNode = document.createElement("video");
      let studioNode = GM.getStudioNode();
      studioNode.style.backgroundImage = "none";
      studioNode.style.backgroundColor = "black";
      meetingGrid.appendChild(studioNode);
      track.attach(GM.getStudioNode());
    } else {
      // Find any available placeholder for other participants
      console.log("Track Owner ID:", track.getParticipantId());
      if (track.isLocal()) {
        videoNode = GM.getLocalNode(track.getParticipantId());
      } else {
        videoNode = GM.getGridNode(track.getParticipantId());
      }      
    }

    // 비디오노드 할당 받았으면
    if (videoNode) {
      videoNode.id = track.getParticipantId();
      videoNode.classList.remove("placeholder");
      videoNode.style.backgroundImage = "none";
      videoNode.className =
        displayName === "스튜디오" ? "jitsiTrack large" : "jitsiTrack";
      videoNode.style.width = "100%";
      videoNode.style.height = "100%";
      videoNode.style.padding = "0 !important";
      videoContainer.appendChild(videoNode);
      meetingGrid.appendChild(videoContainer);

      track.attach(videoNode);

      videoNode.style.opacity = "0"; // 일단 비디오 숨기고

      setTimeout(() => {
        videoNode.style.opacity = "1"; // 애니메이션 실행
        videoContainer.classList.add("animate-in");
      }, 50);

      const nameLabel = document.createElement("div");
      nameLabel.className = "participant-name";
      nameLabel.innerText = displayName;
      videoContainer.appendChild(nameLabel);
    }
  } else if (!track.isLocal()) {
    const audioNode = document.createElement("audio");
    audioNode.id = track.getId();
    audioNode.className = "jitsiTrack";
    audioNode.autoplay = true;
    document.body.appendChild(audioNode);
    track.attach(audioNode);
  }
};

const handleTrackRemoved = (track) => {
  // clean track
  console.log("Track object Removed:", track);
  //GM.cleanGrid(track.getParticipantId());
  track.dispose();
};

const onConferenceJoined = () => {
  console.log("conference joined!");
  state.conference.setDisplayName(state.username);
};

const onConferenceLeft = () => {
  console.log("conference left!");
};

const onUserJoined = (id) => {
  console.log("user joined!", id);
};

const onUserLeft = (id) => {
  // Find the video node and remove it
  if (GM) {
    GM.cleanGrid(id);
  }

  console.log("user left!", id);
};

const onConnectionSuccess = async () => {
  // Initialize conference
  const c = (state.conference = state.connection.initJitsiConference(
    state.room,
    {
      userInfo: {
        displayName: state.username,
      },
    }
  ));

  // Setup event listeners
  c.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, handleTrackAdded);
  c.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, handleTrackRemoved);
  c.on(
    window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
    onConferenceJoined
  );
  c.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
  c.on(window.JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
  c.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);

  try {
    // Create local tracks
    const localTracks = await window.JitsiMeetJS.createLocalTracks({
      devices: ["audio", "video"],
      resolution: 180,
    });

    // Add local tracks before joining
    for (const track of localTracks) {
      if (track.getType() === "audio") {
        // Mute local audio track
        track.mute();
      }
      await c.addTrack(track);
    }
  } catch (e) {
    console.log(e);
  }

  // Join
  c.join();
};

const onConnectionFailed = () => {
  console.error("connection failed!");
};

const onConnectionDisconnected = () => {
  console.log("connection disconnected!");
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Connection functions

function buildOptions(appId, room) {
  return {
    hosts: {
      domain: "8x8.vc",
      muc: `conference.${appId}.8x8.vc`,
    },
    serviceUrl: `wss://8x8.vc/${appId}/xmpp-websocket?room=${room}`,
    websocketKeepAliveUrl: `https://8x8.vc/${appId}/_unlock?room=${room}`,
  };
}

async function connect() {
  const options = buildOptions(state.appId, state.room);
  console.log("connecting with options:", options);

  // Create connection.
  const connection = (state.connection = new window.JitsiMeetJS.JitsiConnection(
    null,
    state.jwt,
    options
  ));
  console.log("state.jwt:", state.jwt);

  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  connection.addEventListener(
    window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    onConnectionDisconnected
  );

  connection.connect();
}

// Leave the room and proceed to cleanup.
async function leave() {
  if (state.conference) {
    await state.conference.leave();
  }

  if (state.connection) {
    await state.connection.disconnect();
  }

  state.connection = undefined;
  state.conference = undefined;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// unused code

function createInitialGrid(gridSize) {
  const meetingGrid = document.getElementById("meeting-grid");
  meetingGrid.innerHTML = ""; // Clear existing grid

  if (gridSize === 20) {
    meetingGrid.style.gridTemplateColumns = "repeat(6, 1fr)";
    meetingGrid.style.gridTemplateRows = "repeat(6, 1fr)";
  } else if (gridSize === 44) {
    meetingGrid.style.gridTemplateColumns = "repeat(12, 1fr)";
    meetingGrid.style.gridTemplateRows = "repeat(12, 1fr)";
  }

  for (let i = 0; i < gridSize; i++) {
    const videoNode = document.createElement("video");
    setBlankGrid(videoNode, i);
    meetingGrid.appendChild(videoNode);
  }

  const studioNode = document.createElement("video");
  studioNode.id = "studio";
  studioNode.className = "jitsiTrack large h-100";

  if (gridSize === 20) {
    studioNode.style.gridColumn = "2 / span 4";
    studioNode.style.gridRow = "1 / span 4";
  } else if (gridSize === 44) {
    studioNode.style.gridColumn = "1 / span 10";
    studioNode.style.gridRow = "1 / span 10";
  }

  studioNode.autoplay = true;
  studioNode.style.backgroundImage = `url(${StudioWait})`;
  studioNode.style.backgroundSize = "cover";
  studioNode.style.backgroundRepeat = "no-repeat";
  studioNode.style.backgroundPosition = "center";
  meetingGrid.appendChild(studioNode);

  //GM = new GridManager(gridSize);
}

// function updateGridLayout(gridSize) {
//   const meetingGrid = document.getElementById("meeting-grid");

//   if (gridSize === 20) {
//     meetingGrid.style.gridTemplateColumns = "repeat(6, 1fr)";
//     meetingGrid.style.gridTemplateRows = "repeat(6, 1fr)";
//   } else if (gridSize === 44) {
//     meetingGrid.style.gridTemplateColumns = "repeat(12, 1fr)";
//     meetingGrid.style.gridTemplateRows = "repeat(12, 1fr)";
//   } else if (gridSize === 1) {
//     meetingGrid.style.gridTemplateColumns = "repeat(1, 1fr)";
//     meetingGrid.style.gridTemplateRows = "repeat(1, 1fr)";
//   }

//   // Update the layout of existing video nodes
//   let videoNodes = meetingGrid.querySelectorAll("video");

//   videoNodes.forEach((videoNode, index) => {
//     if (videoNode.id !== "studio" && !videoNode.classList.contains("large")) {
//       if (index < gridSize) {
//         videoNode.style.display = "block";
//       } else {
//         videoNode.style.display = "none";
//       }
//     }
//   });

//   const studioNode = document.getElementById("studio");
//   const studioClass = document.getElementsByClassName("jitsiTrack large");
//   console.log(studioClass);

//   if (studioNode && studioClass) {
//     if (gridSize === 20) {
//       studioNode.style.gridColumn = "2 / span 4";
//       studioNode.style.gridRow = "1 / span 4";

//       Array.from(studioClass).forEach((studio) => {
//         studio.style.gridColumn = "2 / span 4";
//         studio.style.gridRow = "1 / span 4";
//       });
//     } else if (gridSize === 44) {
//       studioNode.style.gridColumn = "2 / span 10";
//       studioNode.style.gridRow = "1 / span 10";

//       Array.from(studioClass).forEach((studio) => {
//         studio.style.gridColumn = "2 / span 10";
//         studio.style.gridRow = "1 / span 10";
//       });
//     } else if (gridSize === 1) {
//       studioNode.style.gridColumn = "1 / span 1";
//       studioNode.style.gridRow = "1 / span 1";

//       Array.from(studioClass).forEach((studio) => {
//         studio.style.gridColumn = "1 / span 1";
//         studio.style.gridRow = "1 / span 1";
//       });
//     }
//   }
// }
