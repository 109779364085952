import React from 'react';
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import VerticalLogo from "../../assets/common/vertical_logo.png";
import Package from "../../../package.json";

const Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
    overflow-x: hidden; 
    overflow-y: auto;
`;

const InformationPopup = ({
                            show,
                            setShow,
                            deviceId,
                            centerInfo
                          }) => {

  const [allDevices, setAllDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    mediaDevices => {
      setAllDevices(mediaDevices);
      console.log(mediaDevices);
    }, []);

  React.useEffect(() => {
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    } else {
      console.error("enumerateDevices is not supported");
    }
  }, [handleDevices]);

  return (
    <Wrapper onClick={() => setShow(false)}>
      <Row className={"h-100 justify-content-center overflow-auto"}>
        <Col xs={2} className={"align-middle m-auto text-center"}>
          <img src={VerticalLogo} alt={"Logo"} className={"img-fluid w-75"}/>
        </Col>
        <Col xs={8} className={"align-middle m-auto"}>
          <Row className={"rounded-4 p-2"} style={{backgroundColor: "#4F4F4F"}}>
            <Col xs={3}>
              <h4>경로당 명</h4>
            </Col>
            <Col xs={9} className={"text-end"}>
              <h4 style={{color: "rgba(255,255,255,0.7)"}}>{centerInfo.name}</h4>
            </Col>
          </Row>

          <Row className={"mt-1 p-2"}>
            <Col xs={3}>
              <h4>경로당 번호</h4>
            </Col>
            <Col xs={9} className={"text-end"}>
              <h4 style={{color: "rgba(255,255,255,0.7)"}}>{centerInfo.center_id}</h4>
            </Col>
          </Row>

          <Row className={"mt-1 p-2"}>
            <Col xs={3}>
              <h4>버전</h4>
            </Col>
            <Col xs={9} className={"text-end"}>
              <h4 style={{color: "rgba(255,255,255,0.7)"}}
                  onClick={() => {
                    // 페이지 강제 새로고침
                    window.location.href = window.location.href; // 캐시를 비우고 강력 새로고침
                  }}
              >
                ver {Package.version}
              </h4>
            </Col>
          </Row>

          <Row className={"mt-1 p-2"}>
            <Col xs={3}>
              <h4>기기 코드</h4>
            </Col>
            <Col xs={9} className={"text-end"}>
              <h4 style={{color: "rgba(255,255,255,0.7)", wordBreak: "break-all"}}>{deviceId}</h4>
            </Col>
          </Row>

          <Row className={"mt-1 p-2 row-gap-1 rounded-4 "} style={{backgroundColor: "#4F4F4F"}}>
            <Col xs={12}>
              <h4>연결 기기 정보</h4>
            </Col>
            <Col xs={12}>
              <Row className={"row-gap-1"}>
                <hr/>
                {allDevices.map((device, index) => (
                  <>
                    <Col xs={4} className={""}>
                      <h6 style={{wordBreak: "break-all"}}>Kind: {device.kind}</h6>
                    </Col>
                    <Col xs={4}>
                      <h6 style={{wordBreak: "break-all"}}>Label: {device.label}</h6>
                    </Col>
                    <Col xs={4}>
                      <h6 style={{wordBreak: "break-all"}}>Device ID: {device.deviceId}</h6>
                    </Col>
                    <hr/>
                  </>
                ))}
              </Row>
            </Col>
          </Row>

        </Col>
      </Row>
    </Wrapper>
  );
};


export default InformationPopup;