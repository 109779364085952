import React from 'react';
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
// import CautionSVG from "../../../assets/common/caution.svg";
import CautionControl from "../../../assets/common/caution-control.png";

const Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
`;


const ButtonWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    //height: 15vh;
    z-index: 100;
    //background-color: #fff;
    //border-radius: 16px;
    //padding: 1rem;
    //margin: 0 auto;
    //align-content: center;
`;

const ControlPopup = ({show, closePopup}) => {
  if (!show) return null;

  return (
    <Wrapper
      // onClick={closePopup}
    >
      <ButtonWrapper>
        <Row className={"justify-content-center"}>
          {/*<Col xs={1} className={"text-center"}>
            <img src={CautionSVG} alt="Caution" className={"w-100"}/>
          </Col>
          <Col xs={7} className={"my-auto text-center"}>
            <h1 className={"text-black"}>
              마우스 사용이 불가하니 리모컨을 사용해 주시길 바랍니다.
            </h1>
          </Col>*/}
          <Col xs={12}>
            <img src={CautionControl} alt={"Caution Control"} className={"w-100"}/>
          </Col>
        </Row>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ControlPopup;