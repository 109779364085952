import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import styled from "styled-components";
import { renderIcon } from "../../hooks/RenderIcon";
import { Col, Row } from "react-bootstrap";

const MenuItemBox = styled.div`
  background-color: ${({ focused }) =>
    focused === "true" ? "#3FB5EC66" : "#242426"};
  border-color: white;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 7px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  font-size: 2rem;
  //margin-right: 1rem;
  padding: 0.5rem;
  width: 90%; // 포커스 스타일 너비
  margin: 0 auto;
  transition: all 0.2s ease;

  img {
    width: 70%;
  }

  .semi {
    font-weight: 600;
  }
`;

export function MenuItem({ name, label, onSelect, show, focusKey, onClick }) {
  const { ref, focused } = useFocusable({
    focusKey,
    onEnterPress: () => {
      onSelect(label);
    },
    onBlur: () => {
      console.log("onBlur");
    },
  });

  return (
    <>
      <MenuItemBox
        ref={ref}
        // ref={show ? undefined : ref}
        focused={focused.toString()}
        // focused={show ? undefined : focused.toString()}
        data-testid="menu-item"
        onClick={onClick}
      >
        <div
          className={"d-flex justify-content-center align-items-center gap-4"}
        >
          <Col
            xs={3}
            className={
              "text-center d-flex justify-content-center align-items-center"
            }
          >
            <img
              src={renderIcon(name)}
              alt={label}
              className={"icon my-auto"}
            />
          </Col>
          <Col xs={7} className="fs-3 p-0 semi">
            {label}
          </Col>
        </div>
      </MenuItemBox>
    </>
  );
}
