import * as React from "react";
import {
  useFocusable,
  init,
  FocusContext,
  setFocus,
  updateAllLayouts,
} from "@noriginmedia/norigin-spatial-navigation";
import styled from "styled-components";
import { MenuItem } from "./MenuItem";
import { Col, Row } from "react-bootstrap";
import LogoSVG from "../../assets/common/logo-sm.svg";
import Weather from "../../components/Content/weather/weather";
import { useEffect, useState } from "react";
import InformationPopup from "../Information/InformationPopup";
import { SWAGGER_BASE_URL } from "../../config";
import ControlPopup from "../Layout/popup/ControlPopup";

init({
  debug: true,
  visualDebug: false,
});

const MenuWrapper = styled.div`
  //flex: 1;
  flex: 0 0 auto;
  /* width: 19.5%; */
  width: 15vw;
  //max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #242426;
  //padding-top: 37px;
  border-radius: 30px;
`;

export function Menu({
  focusKey: focusKeyParam,
  items,
  selectedComponent,
  onSelect,
  show,
  setShow,
  deviceId,
  centerInfo,
}) {
  const {
    ref,
    focusSelf,
    hasFocusedChild,
    focusKey,
    // setFocus //-- to set focus manually to some focusKey
    // navigateByDirection, -- to manually navigate by direction
    // pause, -- to pause all navigation events
    // resume, -- to resume all navigation events
    // updateAllLayouts -- to force update all layouts when needed
  } = useFocusable({
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: true,
    focusBoundaryDirections: ["up", "down", "left"],
    focusKey: focusKeyParam,
    preferredChildFocusKey: undefined,
    onEnterPress: () => {
      console.log("Enter pressed");
    },
    onEnterRelease: () => {},
    onArrowPress: () => true,
    onFocus: () => {
      console.log("Focused", ref);
    },
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  React.useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  useEffect(() => {
    setTimeout(() => {
      setFocus("IPTV");

      if (selectedComponent === "실시간 방송") {
        setFocus("STREAMING");
      } else if (selectedComponent === "TV 보기") {
        setFocus("IPTV");
      } else if (selectedComponent === "메시지 보기") {
        setFocus("MESSAGE");
      } else if (selectedComponent === "영상 통화") {
        setFocus("VIDEO_CALL");
      } else if (selectedComponent === "버스 정보") {
        setFocus("BUS_ARRIVED");
      }
    }, 0);
  }, [selectedComponent]);

  // const [show, setShow] = useState(false);

  useEffect(() => {
    updateAllLayouts();
    if (!show) {
      focusSelf();
    }
  }, [show]);

  const [showControlAlert, setShowControlAlert] = useState(false);

  const openControlAlert = (item) => {
    console.log("clickedMenuItem");
    setShowControlAlert(true);
  };

  const closeControlAlert = () => {
    console.log("closeControlAlert called");
    setShowControlAlert(false);
  };

  useEffect(() => {
    if (showControlAlert) {
      setTimeout(() => setShowControlAlert(false), 5000);
    }
  }, [showControlAlert]);

  console.log("centerInfo", centerInfo);

  return (
    <>
      {/*<CustomModal
        show={show}
        handleClose={() => setShow(false)}
        focusKey="MODAL"
      />*/}

      {/*<MyModal show={show} onClose={() => setShow(false)}/>*/}
      {/*<FocusContext.Provider value={show ? null : focusKey}>*/}
      <FocusContext.Provider value={focusKey}>
        <MenuWrapper
          ref={ref}
          // ref={show ? undefined : ref}
          // hasFocusedChild={hasFocusedChild}
        >
          {/*hasFocusedChild={show ? undefined : hasFocusedChild}>*/}
          <Row className={"mt-5 row-gap-1 w-100 text-center "}>
            <Col xs={12}>
              <img
                src={LogoSVG}
                alt={"logo"}
                className={"w-75"}
                onClick={() => setShow(true)}
                // onClick={moveIPTV}
              />
            </Col>
            <Col xs={12}>
              <h5 onClick={() => (window.location.href = window.location.href)}>
                {Object.keys(centerInfo).length !== 0 ? (
                  <div>
                    <p>{centerInfo.name}</p>
                    <p>{centerInfo.center_id}</p>
                  </div>
                ) : (
                  // "승인되지 않은 기기입니다."
                  ""
                )}
              </h5>
              {SWAGGER_BASE_URL === "http://192.168.1.111:9000/api/" && (
                <h5>로컬 테스트</h5>
              )}
            </Col>
          </Row>

          <Row className={"mt-3 mb-5"}>
            <Col xs={12}>
              <Weather />
            </Col>
          </Row>

          <Row className={"mt-5 row-gap-4"}>
            {items.map((item) => (
              <Col xs={12} key={item.id}>
                <MenuItem
                  name={item.name}
                  label={item.name_kor}
                  onSelect={onSelect}
                  show={show}
                  focusKey={item.focusKey}
                  onClick={() => openControlAlert()}
                />
              </Col>
            ))}
          </Row>
        </MenuWrapper>
      </FocusContext.Provider>
      {/*<Popup show={show} setShow={() => setShow(false)}/>*/}

      {show ? (
        <InformationPopup
          show={show}
          setShow={() => setShow(false)}
          deviceId={deviceId}
          centerInfo={centerInfo}
        />
      ) : (
        <></>
      )}

      {showControlAlert && (
        <ControlPopup show={showControlAlert} closePopup={closeControlAlert} />
      )}

      {/*{show ? (
        <Popup
          show={show}
          setShow={() => setShow(false)}
        />) : (<></>)}*/}

      {/*{show ? (
        <MyModal
          show={show}
          onClose={() => setShow(false)}
        />) : (<></>)}*/}
    </>
  );
}
