import React, { useState, useEffect } from "react";
import CurrentWeather from "./CurrentWeather";
import WeatherApi from "../../../../api/weatherApi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClipLoader from "react-spinners/ClipLoader";
import { getDate, getTime } from "../../../../util/util";

export default function MainContent({ isFullscreen }) {
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [currentWeather, setCurrentWeather] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [color] = useState("#ffffff");
  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState(undefined);

  useEffect(() => {
    setLocation({ lat: 37.454994, lon: 126.702999 }); // Example: 인천 샤펠드미앙 coordinates
  }, []);

  useEffect(() => {
    const fetchWeatherData = () => {
      if (location.lat && location.lon) {
        setIsLoading(true);
        Promise.all([WeatherApi.getCurrentWeather(location.lat, location.lon)])
          .then(([currentWeatherData]) => {
            setCurrentWeather(currentWeatherData);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching weather data:", error);
            setIsLoading(false);
          });
      }
    };

    fetchWeatherData(); // Fetch data immediately on first render

    const weatherIntervalId = setInterval(fetchWeatherData, 3600000); // Fetch data every hour

    return () => clearInterval(weatherIntervalId); // Clear interval on component unmount
  }, [location]);

  useEffect(() => {
    const updateDateTime = () => {
      const now = Date.now() / 1000; // Current time in Unix timestamp
      setTime(getTime(now));
      setDate(getDate(now));
    };

    updateDateTime(); // Set date and time immediately on first render

    const dateTimeIntervalId = setInterval(updateDateTime, 60000); // Update date and time every minute

    return () => clearInterval(dateTimeIntervalId); // Clear interval on component unmount
  }, []);

  if (isLoading) {
    return (
      <ClipLoader
        color={color}
        loading={isLoading}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    );
  }

  return (
    <Row className={"row-gap-2 p-3"}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={""}>
        <CurrentWeather
          currentWeather={currentWeather}
          isFullscreen={isFullscreen}
          date={date}
          time={time}
        />
      </Col>
    </Row>
  );
}
