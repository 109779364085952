export const weekDayNames = ["일", "월", "화", "수", "목", "금", "토"];

export const monthNames = [
  "1월",
  "2월",
  "3월",
  "4월",
  "5월",
  "6월",
  "7월",
  "8월",
  "9월",
  "10월",
  "11월",
  "12월",
];

export const getDate = function () {
  const now = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Seoul" })
  );

  const weekDayName = weekDayNames[now.getDay()];
  const monthName = monthNames[now.getMonth()];
  return `${monthName} ${now.getDate()}일 ${weekDayName}요일`;
};

export const getTime = function () {
  const now = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Seoul" })
  );

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  // const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  // return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  return `${hours}:${formattedMinutes}`;
};

export const getHours = (timeUnix) => {
  const date = new Date(timeUnix * 1000);
  const hours = date.getHours();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${formattedHours} ${period}`;
};
