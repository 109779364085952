import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import styled from "styled-components";

const RowWrapper = styled.div`
  background-color: ${(props) =>
    props.$centerFocused ? "rgba(63,181,236,0.4)" : "transparent"};
  border-radius: ${(props) => (props.$centerFocused ? "8px" : "")};
  padding: ${(props) => (props.$centerFocused ? "0 0.5rem" : "")};
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
`;

const StyledButton = styled(Button)`
  border: ${(props) =>
    props.$centerFocused
      ? props.$isSelected
        ? "4px solid #fff" // 선택된 상태에서 포커스일 때
        : "4px solid #fff" // 선택되지 않았지만 포커스일 때
      : "4px solid #3FB5EC"}; // 기본 상태

  background-color: ${(props) =>
    props.$centerFocused
      ? props.$isSelected
        ? "" // 선택된 상태에서 포커스일 때
        : "#88D1F3" // 선택되지 않았지만 포커스일 때
      : props.$isSelected
      ? "transparent" // 선택됐지만 포커스가 없을 때
      : "#3FB5EC"}; // 기본 상태

  color: ${(props) =>
    props.$centerFocused
      ? props.$isSelected
        ? "#fff" // 선택된 상태에서 포커스일 때
        : "#000" // 선택되지 않았지만 포커스일 때
      : props.$isSelected
      ? "#3FB5EC" // 선택됐지만 포커스가 없을 때
      : "#000"}; // 기본 상태

  transition: all 0.2s ease;
`;

const CenterRow = ({
  center,
  isSelected,
  boroughNameKor,
  selectSeniorCenter,
  deleteSeniorCenter,
  onFocus,
}) => {
  const {
    ref: centerRef,
    focused: centerFocused,
    focusKey,
  } = useFocusable({
    onFocus: ({ y }) => onFocus({ y }),
    onEnterPress: () => {
      isSelected
        ? deleteSeniorCenter(center, centerRef)
        : selectSeniorCenter(center, centerRef);
    },
  });

  // const rowRef = useRef(null);

  // 포커스가 변경되었을 때, 자동으로 해당 요소로 스크롤 이동
  // useEffect(() => {
  //   if (centerFocused && rowRef.current) {
  //     rowRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center", // 스크롤이 자연스럽게 이동하도록 설정
  //     });
  //   }
  // }, [centerFocused]);

  return (
    <Row
      className="fs-3 text-center align-items-center py-3 mx-4 border-bottom custom-border-secondary"
      ref={centerRef}
      // ref={(el) => {
      //   centerRef.current = el; // `useFocusable()`의 ref 적용
      //   rowRef.current = el; // 스크롤 처리를 위한 ref 적용
      // }}
      focused={centerFocused}
      focusKey={focusKey}
    >
      <RowWrapper
        $centerFocused={centerFocused}
        className="justify-content-center align-items-center py-2"
      >
        <Col xs={1} className="p-0">
          {boroughNameKor}
        </Col>
        <Col xs={2}>{center.center_id || "ICS-000"}</Col>
        <Col xs={6}>{center.name}</Col>
        <Col xs={3}>
          <StyledButton
            data-id={center.id}
            type={"button"}
            $centerFocused={centerFocused}
            $isSelected={isSelected}
            className={`px-5 btn ${
              isSelected ? "btn-outline-primary" : "btn-primary"
            }`}
            // tabIndex={0}
            // onClick={onClick}
            // onFocus={onFocus}
          >
            {isSelected ? "선택해제" : "선택하기"}
          </StyledButton>
        </Col>
      </RowWrapper>
    </Row>
  );
};

export default CenterRow;
