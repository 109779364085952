import styled, {createGlobalStyle} from "styled-components";
import {Menu} from "../components/Menu/Menu";
// import {Content} from "../components/Content/Content";
import {useState, useEffect} from "react";
import MainLayout from "../components/Layout/MainLayout";
import menuJSON from "../data/menus.json";
import shuffle from "lodash/shuffle";
import Footer from "../components/Layout/Footer";
import Streaming from "../components/Content/conf/Streaming";
import Capture from "../components/Content/additional/capture";
import MessageList from "../components/Content/message/MessageList";
import VideoCall from "../components/Content/videoCall/VideoCall";
import BusArrived from "../components/Content/bus/BusArrived";
import {
  getBroadcastStatus,
  getCenterInfo,
  getCenterPolling,
  getMessage,
  getNotice,
  getRecordTvs,
  getVideoCall,
  postCenterInfo,
} from "../api/apiClient";
import {useNavigate} from "react-router-dom";
import useQuery from "../hooks/useQuery";
import Popup from "../components/Layout/popup/Popup";
import {
  setFocus,
  getCurrentFocusKey,
  useFocusable,
  FocusContext,
} from "@noriginmedia/norigin-spatial-navigation";
import KeyboardDebugger from "../hooks/KeyboardDebugger";
import BlockBackNavigation from "../hooks/BlockBackNavigation";
import MessagePopup from "../components/Layout/popup/MessagePopup";

// const menuItems = ['실시간 방송', 'TV 보기', '메시지 보기', '영상 통화', '버스 정보'];
// const menuItems = menuJSON;

export const rows = shuffle([
  {
    title: "Recommended",
  },
  {
    title: "Movies",
  },
  {
    title: "Series",
  },
  {
    title: "TV Channels",
  },
  {
    title: "Sport",
  },
]);

const menuItems = [
  "실시간 방송",
  "TV 보기",
  "메시지 보기",
  "영상 통화",
  "버스 정보",
];

export const assets = [
  {
    title: "Asset 1",
    color: "#714ADD",
  },
  {
    title: "Asset 2",
    color: "#AB8DFF",
  },
  {
    title: "Asset 3",
    color: "#512EB0",
  },
  {
    title: "Asset 4",
    color: "#714ADD",
  },
  {
    title: "Asset 5",
    color: "#AB8DFF",
  },
  {
    title: "Asset 6",
    color: "#512EB0",
  },
  {
    title: "Asset 7",
    color: "#714ADD",
  },
  {
    title: "Asset 8",
    color: "#AB8DFF",
  },
  {
    title: "Asset 9",
    color: "#512EB0",
  },
];

function Main() {
  const navigate = useNavigate();
  const query = useQuery();

  // 입력 디버그
  const keyboard = KeyboardDebugger();

  useEffect(() => {
    if (keyboard === "k" || keyboard === "ContextMenu") {
      moveStreaming();
    } else if (keyboard === "l" || keyboard === "BrowserHome") {
      moveIPTV();
    } else if (keyboard === "h" || keyboard === "Backspace") {
      // moveHome();
    } else if (keyboard === "f") {
      // handleToggleFullscreen();
    }
  }, [keyboard]);

  const blockBackNavigation = BlockBackNavigation();

  const [selectedComponent, setSelectedComponent] = useState("TV 보기");
  const [menuItems] = useState(menuJSON);

  const [deviceId, setDeviceId] = useState(query.deviceID);
  const [centerInfo, setCenterInfo] = useState({});

  // 디버그용 정보 팝업
  const [showInformationPopup, setShowInformationPopup] = useState(false);

  // 알림
  const [showMessageAlert, setShowMessageAlert] = useState(false);
  // const [showVideoCallAlert, setShowVideoCallAlert] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  const [noticeInfo, setNoticeInfo] = useState({});
  const [noticeCount, setNoticeCount] = useState(0);
  const [latestMessage, setLatestMessage] = useState(null);
  const [latestVideoCall, setLatestVideoCall] = useState(null);

  // 전체 화면
  const [isFullScreen, setIsFullScreen] = useState(false);

  // 메시지 팝업
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  // 기기 정보 등록 (diviceId)
  const postCenter = () => {
    const center_code = centerInfo.center_id;
    const settop_sn = deviceId;

    const data = {
      center_code,
      settop_sn,
    };

    console.log("postCenter data: ", data);

    postCenterInfo(data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // centerInfo 정보 불러오기
  useEffect(() => {
    if (!deviceId) {
      navigate("/404");
    } else {
      getCenterInfo(deviceId)
        .then((res) => {
          console.log("get center response: ", res.data);
          if (typeof res.data === "object" && res.data !== null) {
            setCenterInfo(res.data);
            // res가 있을 시 로컬스토리지에 저장
            if (res.data.center_id) {
              localStorage.setItem("centerInfo", JSON.stringify(res.data));
            }
          } else {
            console.error("Invalid response: Data is not an object", res.data);
            // navigate("/404");
          }
          setCenterInfo(res.data);
        })
        .catch((err) => {
          console.error(err);
          // res가 없을 시 로컬 스토리지에 저장된 center_id로 다시 요청
          const localCenterInfo = JSON.parse(
            localStorage.getItem("centerInfo")
          );
          if (localCenterInfo && localCenterInfo.center_id) {
            setCenterInfo(localCenterInfo);

            // 로컬 스토리지에 저장된 데이터를 사용할 경우 DB에 업데이트
            // postCenter();
          }
          // 만약 로컬 스토리지에 저장된 center_id가 없을 시 404로 이동
          else {
            navigate(`/404?deviceID=${deviceId}`);
          }
        });
    }
  }, [deviceId]);

  // 기기 정보 등록
  useEffect(() => {
    if (centerInfo.center_id) {
      postCenter();
    }
  }, [centerInfo]);

  // 방송 송출에서 전환 클릭 시 해당 컴포넌트 렌더링
  // useEffect(() => {
  //   setInterval(() => {
  //     getBroadcastStatus()
  //       .then((res) => {
  //         console.log("broadcast status: ", res.data);
  //         // if (res.data.status === "free") {
  //         if (res.data.status === "streaming") {
  //           moveStreaming();
  //           // } else if (res.data.status === "free") {
  //         } else if (res.data.status === "iptv") {
  //           moveIPTV();
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }, 10000);
  // }, []);

  useEffect(() => {
    if (centerInfo && centerInfo?.center_id !== undefined) {
      setInterval(() => {
        getCenterPolling(centerInfo?.center_id)
          .then((res) => {
            console.log("broadcast status: ", res.data);
            // if (res.data.broadcast_status === "free") {
            if (res.data.broadcast_status === "streaming") {
              moveStreaming();
              // } else if (res.data.broadcast_status === "free") {
            } else if (res.data.broadcast_status === "iptv") {
              moveIPTV();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 10000);
    }
  }, []);

  // '실시간 방송' 으로 화면 강제 전환
  const moveStreaming = () => {
    setSelectedComponent("실시간 방송");
    // setFocus("STREAMING");
    setFocus("fullScreen");
    setIsFullScreen(true);
  };

  // 'IPTV' 로 화면 강제 전환
  const moveIPTV = () => {
    setSelectedComponent("TV 보기");
    // setFocus("IPTV");
    setFocus("fullScreen");
    setIsFullScreen(true);
  };

  // 최신 공지사항 get
  // const getNoticeLatest = () => {
  //   if (centerInfo && centerInfo?.center_id !== undefined) {
  //     getNotice(centerInfo?.center_id)
  //       .then((res) => {
  //         // console.log(res);
  //         if (window.localStorage.getItem("notice") === undefined) {
  //           window.localStorage.setItem("notice", JSON.stringify(res.data));
  //         }

  //         const notice = JSON.parse(window.localStorage.getItem("notice"));

  //         if (notice === undefined) {
  //           window.localStorage.setItem("notice", JSON.stringify(res.data));
  //         }

  //         if (notice && res.data.id !== notice.id) {
  //           openPopup("POPUP_NOTICE");
  //           setShowNotice(true);
  //           setNoticeInfo(res.data);
  //           setNoticeCount(1);
  //           window.localStorage.setItem("notice", JSON.stringify(res.data));
  //         } else if (!notice) {
  //           openPopup("POPUP_NOTICE");
  //           setShowNotice(true);
  //           setNoticeInfo(res.data);
  //           setNoticeCount(1);
  //           window.localStorage.setItem("notice", JSON.stringify(res.data));
  //         }
  //       })
  //       .catch((err) => {
  //         // console.error(err);
  //       });
  //   }
  // };

  // polling api 변경
  const getNoticeLatest = () => {
    if (centerInfo && centerInfo?.center_id !== undefined) {
      // getNotice(centerInfo?.center_id)
      getCenterPolling(centerInfo?.center_id)
        .then((res) => {
          // console.log(res);
          if (window.localStorage.getItem("notice") === undefined) {
            window.localStorage.setItem(
              "notice",
              JSON.stringify(res.data.notice)
            );
          }

          const notice = JSON.parse(window.localStorage.getItem("notice"));

          if (notice === undefined) {
            window.localStorage.setItem(
              "notice",
              JSON.stringify(res.data.notice)
            );
          }

          if (notice && res.data.notice.id !== notice.id) {
            openPopup("POPUP_NOTICE");
            setShowNotice(true);
            // setNoticeInfo(res.data);
            setNoticeInfo(res.data.notice);
            setNoticeCount(1);
            window.localStorage.setItem(
              "notice",
              JSON.stringify(res.data.notice)
            );
          } else if (!notice) {
            openPopup("POPUP_NOTICE");
            setShowNotice(true);
            setNoticeInfo(res.data.notice);
            setNoticeCount(1);
            window.localStorage.setItem(
              "notice",
              JSON.stringify(res.data.notice)
            );
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  // 최신 메시지 get
  // const getMessageLatest = () => {
  //   if (centerInfo && centerInfo?.center_id !== undefined) {
  //     getMessage(true, centerInfo?.center_id)
  //       .then((res) => {
  //         console.log("message: ", res.data);

  //         if (window.localStorage.getItem("message") === undefined) {
  //           window.localStorage.setItem("message", JSON.stringify(res.data[0]));
  //         }
  //         const message = JSON.parse(window.localStorage.getItem("message"));

  //         if (message === undefined) {
  //           window.localStorage.setItem("message", JSON.stringify(res.data[0]));
  //         }

  //         if (message && res.data[0].id !== message.id) {
  //           setLatestMessage(res.data[0]);
  //           setShowMessageAlert(true);
  //           openPopup("POPUP_MESSAGE");
  //           window.localStorage.setItem("message", JSON.stringify(res.data[0]));
  //         } else if (!message) {
  //           setLatestMessage(res.data[0]);
  //           setShowMessageAlert(true);
  //           openPopup("POPUP_MESSAGE");
  //           window.localStorage.setItem("message", JSON.stringify(res.data[0]));
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  // };

  const getMessageLatest = () => {
    if (centerInfo && centerInfo?.center_id !== undefined) {
      // getMessage(true, centerInfo?.center_id)
      getCenterPolling(centerInfo?.center_id)
        .then((res) => {
          console.log("message: ", res.data.message);

          if (window.localStorage.getItem("message") === undefined) {
            window.localStorage.setItem(
              "message",
              JSON.stringify(res.data.message)
            );
          }
          const message = JSON.parse(window.localStorage.getItem("message"));

          if (message === undefined) {
            window.localStorage.setItem(
              "message",
              JSON.stringify(res.data.message)
            );
          }

          if (message && res.data.message.id !== message.id) {
            setLatestMessage(res.data.message);
            setShowMessageAlert(true);
            openPopup("POPUP_MESSAGE");
            window.localStorage.setItem(
              "message",
              JSON.stringify(res.data.message)
            );
          } else if (!message) {
            setLatestMessage(res.data.message);
            setShowMessageAlert(true);
            openPopup("POPUP_MESSAGE");
            window.localStorage.setItem(
              "message",
              JSON.stringify(res.data.message)
            );
          }
        })
        .catch((err) => {
        });
    }
  };

  // 최신 영상통화 get
  // const getVideoCallLatest = () => {
  //   if (centerInfo && centerInfo?.center_id !== undefined) {
  //     console.log("getVideoCallLatest");

  //     getVideoCall(centerInfo?.center_id)
  //       .then((res) => {
  //         console.log("videoCall: ", res.data);

  //         if (window.localStorage.getItem("videoCall") === undefined) {
  //           window.localStorage.setItem("videoCall", JSON.stringify(res.data));
  //         }

  //         const videoCall = JSON.parse(
  //           window.localStorage.getItem("videoCall")
  //         );
  //         if (videoCall === undefined) {
  //           window.localStorage.setItem("videoCall", JSON.stringify(res.data));
  //         }

  //         if (videoCall && res.data.id !== videoCall.id) {
  //           setLatestVideoCall(res.data);
  //           // setShowVideoCallAlert(true);
  //           openPopup("POPUP_CALL");
  //           window.localStorage.setItem("videoCall", JSON.stringify(res.data));
  //         } else if (!videoCall) {
  //           setLatestVideoCall(res.data);
  //           // setShowVideoCallAlert(true);
  //           openPopup("POPUP_CALL");
  //           window.localStorage.setItem("videoCall", JSON.stringify(res.data));
  //         }
  //       })
  //       .catch((err) => {
  //         // console.error(err);
  //       });
  //   }
  // };

  const getVideoCallLatest = () => {
    if (centerInfo && centerInfo?.center_id !== undefined) {
      console.log("getVideoCallLatest");

      getCenterPolling(centerInfo?.center_id)
        .then((res) => {
          console.log("videoCall: ", res.data.video_call);

          if (window.localStorage.getItem("videoCall") === undefined) {
            window.localStorage.setItem(
              "videoCall",
              JSON.stringify(res.data.video_call)
            );
          }

          const videoCall = JSON.parse(
            window.localStorage.getItem("videoCall")
          );
          if (videoCall === undefined) {
            window.localStorage.setItem(
              "videoCall",
              JSON.stringify(res.data.video_call)
            );
          }

          if (videoCall && res.data.video_call.id !== videoCall.id) {
            setLatestVideoCall(res.data.video_call);
            // setShowVideoCallAlert(true);
            openPopup("POPUP_CALL");
            window.localStorage.setItem(
              "videoCall",
              JSON.stringify(res.data.video_call)
            );
          } else if (!videoCall) {
            setLatestVideoCall(res.data.video_call);
            // setShowVideoCallAlert(true);
            openPopup("POPUP_CALL");
            window.localStorage.setItem(
              "videoCall",
              JSON.stringify(res.data.video_call)
            );
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  // 폴링
  useEffect(() => {
    setInterval(() => {
      getNoticeLatest();
      getMessageLatest();
      getVideoCallLatest();
    }, 10000);
  }, [centerInfo]);

  // 공지사항 반복
  useEffect(() => {
    if (noticeInfo.repeat_time && noticeCount <= noticeInfo.repeat_time) {
      if (noticeCount === noticeInfo.repeat_time) {
        setTimeout(() => {
          setShowNotice(false);
        }, 15000);
      } else {
        setTimeout(() => {
          setShowNotice(false);
        }, 14900);
        setTimeout(() => {
          setShowNotice(true);
          setNoticeCount(noticeCount + 1);
        }, 15000);
      }
    }
  }, []);

  // 영상통화 "연결" 버튼을 눌렀을 때 실행
  const handleConfirmCall = (popupKey) => {
    console.log("영상통화 연결", popupKey);
    closePopup("POPUP_CALL");
    setIsCallAccepted(true);
    setSelectedComponent("영상 통화"); // 컴포넌트 이동
    setFocus("VIDEO_CALL");
  };

  // 영상통화 "취소" 버튼을 눌렀을 때 실행
  const handleRejectCall = () => {
    if (!isCallAccepted) {
      setIsCallAccepted(false); // "취소" 버튼을 눌렀을 때만 상태 변경
      closePopup("POPUP_CALL");
    }
  };

  useEffect(() => {
    // 메뉴가 변경될 때 실행됨
    if (selectedComponent !== "영상 통화") {
      setIsCallAccepted(false); // 메뉴가 변경되면 영상통화 종료
    }
  }, [selectedComponent]);

  const renderComponent = (component) => {
    switch (component) {
      case "실시간 방송":
        // return <div>실시간 방송 ver {Package.version}</div>;
        // return <Content rows={rows} focusKey="CONTENT" />;

        return (
          <Streaming
            centerInfo={centerInfo}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            focusKey={"STREAM"}
          />
        );
      case "TV 보기":
        return (
          <Capture
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            focusKey="CAPTURE"
          />
        );

      case "메시지 보기":
        return <MessageList centerInfo={centerInfo} focusKey="MESSAGELIST"/>;
      case "영상 통화":
        return (
          <VideoCall
            centerInfo={centerInfo}
            deviceId={deviceId}
            // videoCallInfo={latestVideoCall}
            videoCallInfo={isCallAccepted ? latestVideoCall : null}
            isCallAccepted={isCallAccepted}
            focusKey="VIDEOCALL"
          />
        );
      case "버스 정보":
        return <BusArrived centerInfo={centerInfo}/>;
      default:
        return (
          <Capture
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            focusKey="CAPTURE"
          />
        );
    }
  };

  const [openPopups, setOpenPopups] = useState([]);
  const [previousFocusStack, setPreviousFocusStack] = useState([]); // 스택으로 관리

  useEffect(() => {
    console.log("openPopups:", openPopups);
  }, [openPopups]);

  const openPopup = (popupKey) => {
    const currentFocusKey = getCurrentFocusKey(); // Save the current focus

    setOpenPopups((prev) => {
      if (!prev.includes(popupKey)) {
        // Check if the popup is already open
        setPreviousFocusStack((prevStack) => [...prevStack, currentFocusKey]); // Push to stack
        setTimeout(() => setFocus(popupKey), 0); // Move focus to the new popup
        return [...prev, popupKey];
      }
      return prev;
    });
  };

  const closePopup = (popupKey) => {
    console.log("popupKey: ", popupKey);

    setOpenPopups((prev) => {
      const newPopups = prev.filter((key) => key !== popupKey);
      setTimeout(() => {
        setPreviousFocusStack((prevStack) => {
          const newStack = [...prevStack];
          const lastFocusKey = newStack.pop(); // 가장 마지막 포커스 키 가져오기

          if (newPopups.length > 0) {
            setFocus(newPopups[newPopups.length - 1]); // 남은 팝업 중 마지막 팝업에 포커스
          } else if (lastFocusKey) {
            setFocus(lastFocusKey); // 이전 포커스로 이동
          } else {
            setFocus("MENU"); // 모든 팝업이 닫히면 기본 UI로 이동
          }

          return newStack;
        });
      }, 0);

      return newPopups;
    });
  };

  const openMessagePopup = () => {
    setShowMessagePopup(true);
    setTimeout(() => setFocus("MESSAGEPOPUP"), 0);
  };

  const closeMessagePopup = () => {
    setShowMessagePopup(false);
    setFocus("MESSAGE");
  };

  const handleAlertCancel = (popupKey) => {
    setShowMessageAlert(false);
  };

  const handleMessageConfirm = (popupKey) => {
    closePopup(popupKey);
    setShowMessageAlert(false);
    // setShowMessagePopup(true);
    // setTimeout(() => setFocus("MESSAGEPOPUP"), 0); // Move focus to the MessagePopup
    openMessagePopup();
  };

  const handleVideoCallCancel = (popupKey) => {
    // setShowVideoCallAlert(false);
  };

  const handleVideoCallConfirm = (popupKey) => {
    closePopup(popupKey);
    // setShowVideoCallAlert(false);
    // 영상통화 페이지로 이동
    // moveVideoCall();
  };

  const handleNoticeConfirm = (popupKey) => {
    setShowNotice(false);
  };

  // 사용자 메뉴 접근 유지 시간 전달
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    console.log("Currently rendering menu:", selectedComponent);
  }, [selectedComponent]);

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(Date.now());
    console.log("centerInfo:", centerInfo);

    // Calculate the time spent when the component unmounts
    const endTime = Date.now();
    const timeSpent = Math.floor((endTime - startTime) / 1000); // Convert to seconds and remove decimal part

    const transferActiveMenu = (activeMenu) => {
      switch (activeMenu) {
        case "실시간 방송":
          return "live_streaming";
        case "TV 보기":
          return "iptv";
        case "메시지 보기":
          return "message";
        case "영상 통화":
          return "video_call";
        case "버스 정보":
          return "bus";
        default:
          return "";
      }
    };

    getRecordTvs(
      centerInfo.center_id,
      transferActiveMenu(selectedComponent),
      timeSpent,
      ""
    )
      .then((res) => {
        console.log("getRecordTvs res : ", res.data);
      })
      .catch((error) => {
        console.log("getRecordTvs error: ", error);
        console.log("centerInfo: ", centerInfo);
        console.error(error);
      });
  }, [selectedComponent]);

  return (
    <MainLayout isFullScreen={isFullScreen}>
      <GlobalStyle/>

      {!isFullScreen && (
        <Menu
          focusKey={"MENU"}
          items={menuItems}
          selectedComponent={selectedComponent}
          onSelect={setSelectedComponent}
          show={showInformationPopup}
          setShow={() => setShowInformationPopup(!showInformationPopup)}
          deviceId={deviceId}
          centerInfo={centerInfo}
        />
      )}

      <MainWrapper
        isFullScreen={isFullScreen}
        selectedComponent={selectedComponent}
      >
        <ContentWrapper
          className="content"
          isFullScreen={isFullScreen}
          selectedComponent={selectedComponent}
        >
          {renderComponent(selectedComponent)}
        </ContentWrapper>

        {(selectedComponent === "실시간 방송" ||
          selectedComponent === "TV 보기" ||
          selectedComponent === null) &&
        !isFullScreen ? (
          <Footer selectedComponent={selectedComponent}/>
        ) : null}
      </MainWrapper>

      {openPopups.map((popupKey) => (
        <Popup
          key={popupKey}
          show
          popupKey={popupKey}
          type={popupKey.replace("POPUP_", "").toLowerCase()} // "notice", "message", "call"
          text={
            popupKey === "POPUP_NOTICE"
              ? noticeInfo?.content
              : popupKey === "POPUP_MESSAGE"
                ? `${latestMessage?.author}님이 보낸 메시지가 도착하였습니다. 확인하시겠습니까?`
                : popupKey === "POPUP_CALL"
                  ? `${latestVideoCall?.center_name}에서 영상 통화를 요청하였습니다. 수락하시겠습니까?`
                  : ""
          }
          onCancel={() => {
            if (popupKey === "POPUP_CALL") {
              handleRejectCall();
            }
            closePopup(popupKey);
          }}
          // onConfirm={() => closePopup(popupKey)}
          onConfirm={() => {
            if (popupKey === "POPUP_MESSAGE") {
              handleMessageConfirm(popupKey);
            } else if (popupKey === "POPUP_CALL") {
              // handleVideoCallConfirm(popupKey);
              handleConfirmCall(popupKey);
            } else if (popupKey === "POPUP_NOTICE") {
              handleNoticeConfirm(popupKey);
            } else {
              closePopup(popupKey);
            }
          }}
        />
      ))}

      {showMessagePopup && latestMessage && (
        <MessagePopup
          popupKey={"MESSAGEPOPUP"}
          message={latestMessage}
          show={showMessagePopup}
          onClose={closeMessagePopup}
        />
      )}
    </MainLayout>
  );
}

export default Main;

// ============== styled-component ==============
const AppContainer = styled.div`
    background-color: #221c35;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
`;

const MainWrapper = styled.div`
    /* position: relative; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({selectedComponent}) =>
            selectedComponent === "실시간 방송" || selectedComponent === "TV 보기"
                    ? "0"
                    : "0.5rem"};
    //gap: 0.5rem;
        //border: ${({isFullScreen}) => (isFullScreen ? "none" : "1px solid #fff")};
`;

const ContentWrapper = styled.div`
    flex: 1;
    position: ${(props) =>
            props.selectedComponent === "영상 통화" ? "" : "relative"};
        //border: ${({isFullScreen}) => (isFullScreen ? "none" : "1px solid #fff")};
    border-radius: ${({isFullScreen}) => (isFullScreen ? "0" : "30px")};
`;

const GlobalStyle = createGlobalStyle`
    ::-webkit-scrollbar {
        /* display: none; */
    }
`;
